<template>
  <div class="order app__page">
    <div class="order__actions">
      <div class="order__actions__top">
        <v-button @clicked="goBack" icon-file-name="back-arrow.png" width="25px" height="35px" />
        <div class="order__actions__top__info">
          <div class="order__actions__top__info__title">{{ orderInfo.dobavljac }}</div>
          <div class="order__actions__top__info__number">{{ orderInfo.broj }}</div>
        </div>
        <div class="order__actions__top__date">
          {{ orderInfo.datum | formatDate }}
        </div>
      </div>
      <div class="order__actions__bottom">
        <v-button :disabled="!changeAllowed" @clicked="changeOrder" width="100px">
          Izmenite
        </v-button>
      </div>
    </div>
    <v-table
      class="order__table"
      :loading="loading"
      :table-headings="orderTableHeadings"
      @page-changed="pageChanged"
      show-pagination
    >
      <order-table-row
        v-for="rowData in order"
        :order-row-data="rowData"
        :key="rowData.id"
        :supplier-id="supplierId"
        @code-added="addCode"
      />
    </v-table>
    <div class="order__save" v-if="codes.length > 0">
      <v-button @clicked="cancelOrder" icon-file-name="cancel-icon.png" width="95px">
        Odbaci
      </v-button>
      <v-button @clicked="saveOrder" icon-file-name="save-icon.png" width="95px">Sačuvaj</v-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VTable from '@/components/table/VTable.vue';
import OrderTableRow from '@/components/orders/OrderTableRow.vue';

export default {
  name: 'Order',
  components: {
    VButton,
    VTable,
    OrderTableRow,
  },
  data() {
    return {
      searchQuery: '',
      codes: [],
    };
  },
  computed: {
    ...mapGetters({
      order: 'orders/orderPageData',
      orderInfo: 'orders/orderInfo',
      loading: 'orders/orderLoading',
      tableHeadings: 'orders/orderTableHeadings',
      changeAllowed: 'orders/orderSendingAllowed',
      supplierId: 'orders/orderSupplierId',
    }),
    orderTableHeadings() {
      const tableHeadings = {};
      Object.keys(this.tableHeadings).forEach((key) => {
        const { exists } = this.tableHeadings[key];
        if ((typeof exists === 'boolean' && exists) || exists.includes(this.supplierId)) {
          tableHeadings[key] = this.tableHeadings[key];
        }
      });
      return tableHeadings;
    },
  },
  methods: {
    goBack() {
      this.codes.length = 0;
      this.$emit('switch-component', 'Orders');
    },
    pageChanged() {
      this.$store.dispatch('orders/orderChangePage');
    },
    addCode(code) {
      const index = this.codes.findIndex((item) => item.itemId === code.itemId);
      if (index === -1) {
        this.codes.push(code);
      } else {
        this.codes[index].newCode = code.newCode;
      }
    },
    saveOrder() {
      this.$store.dispatch('orders/addCode', this.codes);
      this.codes = [];
    },
    cancelOrder() {
      this.$store.dispatch('orders/getOrder', this.orderInfo.id);
      this.codes = [];
    },
    changeOrder() {
      const { id } = this.orderInfo;
      this.$router.push({ name: 'create-order', params: { id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  display: flex;
  flex-direction: column;
  &__actions {
    padding: 10px 10px 0 10px;
    &__top {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      &__info {
        margin-left: 10px;
        &__title {
          font-size: 16px;
          font-weight: 700;
          color: #666666;
        }
        &__number {
          font-size: 12px;
          color: #666666;
        }
      }
      &__date {
        margin-left: auto;
        color: #666666;
      }
    }
    &__bottom {
      margin-top: 10px;
    }
  }
  &__table {
    padding: 0 10px 10px;
    margin-top: 12px;
  }
  &__save {
    padding-left: 10px;
    background-color: #cccccc;
    height: 45px;
    display: flex;
    align-items: center;
    .v-button {
      &:last-of-type {
        margin-left: 10px;
      }
    }
  }
}
</style>
