<template>
  <div class="contact app__page">
    <div class="contact__row">
      <div>
        <h5 :style="{ 'color': branding.color }">komercijala</h5>
        <p>T: 021/419-035</p>
        <p>E: <a href="mailto:komercijala@nspharm.com">komercijala@nspharm.com</a></p>
      </div>
      <div>
        <h5 :style="{ 'color': branding.color }">reklamacije</h5>
        <p>T: 021/472-7899</p>
        <p>E: <a href="mailto:reklamacije@nspharm.com">reklamacije@nspharm.com</a></p>
      </div>
    </div>
    <div class="contact__row">
      <div>
        <h5 :style="{ 'color': branding.color }">knjigovodstvo</h5>
        <p>T: 021/4724-956</p>
        <p>F: 021/63-38-649</p>
        <p>E: <a href="mailto:knjigovodstvo@nspharm.com">knjigovodstvo@nspharm.com</a></p>
      </div>
    </div>
    <div class="contact__row"></div>
  </div>
</template>

<script>
export default {
  name: 'ContactView',
  created() {
    this.$store.dispatch('app/setPageTitle', 'Kontakti');
  },
  computed: {
    branding() {
      return this.$store.getters['app/branding'];
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  &__row {
    display: flex;
    gap: 50px;
    h5 {
      font-size: 20px;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
  }
}
</style>
