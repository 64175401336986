import Api from '../Api';

export default class Products extends Api {
  async getProducts({ pageNum, pageSize, sortBy, sortDesc, query, supplierId, customerId }) {
    let url = `/products/bySupplier/${supplierId}?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}&customerId=${customerId}`;
    if (query) {
      url = `${url}&query=${query}`;
    }
    const response = await this.get(url);
    return response;
  }
}
