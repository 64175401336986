const set = (state, { visible, text, status }) => {
  state.visible = visible;
  state.text = text;
  state.status = status;
  if (visible) {
    state.timeout = setTimeout(() => {
      state.visible = false;
    }, 5000);
  } else {
    clearTimeout(state.timeout);
  }
};

export default {
  set,
};
