<template>
  <div
    class="v-table__body__row products-table-row"
    :class="{ 'products-table-row--active': productData.active }"
    @click="rowClick"
  >
    <div
      v-for="(item, key) in productsTableColumns"
      :key="key"
      class="v-table__body__row__data products-table-row__data"
      :style="{ flex: item.columnWidth || '1' }"
    >
      {{ cellDisplayValue(key) }}
    </div>
    <div class="v-table__body__row__data products-table-row__data products-table-row__data--amount">
      <v-button
        @click.native.stop
        @clicked="minusClick"
        :disabled="amount === 0"
        width="20px"
        height="20px"
        v-show="editingAmount"
      >
        -
      </v-button>
      <v-input
        :ref="productData.code"
        input-type="number"
        width="40px"
        @value-change="inputChange"
        @keyup.stop.prevent.native.enter="addToOrder"
        @keyup.stop.prevent.native.esc="cancelAmountEditing"
        :input-disabled="!editingAmount"
        v-show="amount > 0 || editingAmount"
      />
      <v-button @click.native.stop @clicked="plusClick" width="20px" height="20px">+</v-button>
      <v-button
        @click.native.stop
        @clicked="addToOrder"
        width="20px" height="20px"
        v-show="editingAmount"
      >
        &#10003;
      </v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';

export default {
  name: 'ProductsTableRow',
  components: {
    VButton,
    VInput,
  },
  props: {
    productData: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      amount: 0,
    };
  },
  computed: {
    allColumns() {
      return this.$store.getters['createOrder/productsTableHeadings'];
    },
    productsTableColumns() {
      const tableHeadings = {};
      Object.keys(this.allColumns).forEach((key) => {
        const { exists } = this.allColumns[key];
        if (key !== 'amount' && ((typeof exists === 'boolean' && exists) || exists.includes(this.supplierId))) {
          tableHeadings[key] = this.allColumns[key];
        }
      });
      return tableHeadings;
    },
    editingAmount() {
      return this.productData.editingAmount;
    },
  },
  watch: {
    editingAmount(val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs[this.productData.code].focus();
        });
      }
    },
  },
  methods: {
    cellDisplayValue(key) {
      if (key === 'available') {
        if (typeof this.productData.available === 'boolean') {
          return this.productData.available ? 'Da' : 'Ne';
        }
        return this.productData.available;
      }
      if (key === 'discount') {
        if (!this.productData.discount) {
          return '-';
        }
        return this.supplierId === 9 && this.productData.worthiness ? `${this.productData.discount}*` : this.productData.discount;
      }
      if (key === 'taxRate' || key === 'price') {
        return parseFloat(this.productData[key]).toFixed(2) || '-';
      }
      return this.productData[key] || '-';
    },
    inputChange(val) {
      this.amount = val;
    },
    addToOrder() {
      this.$store.dispatch('createOrder/addItemToNewOrder', {
        payload: {
          ...this.productData,
          amount: this.amount,
        },
        addToLocalStorage: !this.$route.params.id,
      });
      this.$refs[this.productData.code].blur();
      this.$emit('product-added');
      this.$store.dispatch('createOrder/editProductRow', false);
    },
    minusClick() {
      this.amount -= 1;
      this.$refs[this.productData.code].value = this.amount;
    },
    async plusClick() {
      if (this.editingAmount) {
        this.amount += 1;
      } else {
        await this.$store.dispatch('createOrder/setActiveProductRow', this.productData.code);
        this.$store.dispatch('createOrder/editProductRow', true);
      }
      this.$nextTick(() => {
        this.$refs[this.productData.code].value = this.amount;
      });
    },
    cancelAmountEditing() {
      this.$store.dispatch('createOrder/editProductRow', false);
      this.$emit('blur-row');
    },
    rowClick() {
      this.$store.dispatch('createOrder/setActiveProductRow', this.productData.code);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-table-row {
  &:hover,
  &--active {
    background-color: #e6e6e6;
  }
  &__data {
    &--amount {
      display: flex;
      .v-button,
      .v-input {
        margin-right: 10px;
      }
      .v-input {
        height: 20px;
      }
    }
  }
}
</style>
