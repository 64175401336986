const wrongCredentialsError = (state) => state.wrongCredentialsError;
const authLoading = (state) => state.authLoading;
const userActions = (state) => state.userActions;
const invoiceActions = (state) => state.invoiceActions;
const invoicesActions = (state) => state.invoicesActions;
const ordersActions = (state) => state.ordersActions;
const createOrderActions = (state) => state.createOrderActions;
const customersLocations = (state) => {
  if (state.customersLocations.length === 1) {
    return state.customersLocations;
  }
  return [{ name: 'Svi korisnici', id: null }, ...state.customersLocations];
};
const customersLocationsForOrder = (state) => {
  if (state.customersLocations.length === 1) {
    return state.customersLocations;
  }
  return [{ name: 'Izaberi isporučno mesto', id: null }, ...state.customersLocations];
};
const customersSuppliers = (state) => [
  { name: 'Izaberi dobavljača', id: null, nonSelectable: true },
  ...state.customersSuppliers,
];
const customerId = (state) => state.customerId;
const user = (state) => {
  if (Object.keys(state.user).length) {
    return state.user;
  }
  return false;
};
const userRole = (state) => state.user.role;
const userId = (state) => state.user.userID;
const token = () => localStorage.getItem('token');
const changePasswordApiText = (state) => state.changePasswordApiText;
const portalInfo = (state) => state.portalInfo;

export default {
  wrongCredentialsError,
  authLoading,
  user,
  userRole,
  userId,
  userActions,
  invoiceActions,
  invoicesActions,
  ordersActions,
  createOrderActions,
  customersLocations,
  customersLocationsForOrder,
  customersSuppliers,
  customerId,
  token,
  changePasswordApiText,
  portalInfo,
};
