import PackingListsApi from '../../api/packingLists/index';

const packingListsApi = new PackingListsApi();

const getPackingLists = async ({ commit, getters, rootGetters }) => {
  try {
    commit('setPackingListsLoading', true);
    const pageNum = rootGetters['tablePagination/currentPage'];
    const customerId = rootGetters['user/customerId'];
    commit('setPackingListsQueryParams', { pageNum, customerId });
    const { packingListsQueryParams } = getters;
    const { pageSize } = packingListsQueryParams;
    const {
      data: { documents, total },
    } = await packingListsApi.getPackingLists(packingListsQueryParams);
    commit('tablePagination/setNextButtonDisabledState', total < pageSize, { root: true });
    commit('setPackingLists', documents);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setPackingListsLoading', false);
  }
};

const setPackingListsQueryParams = ({ commit }, payload) => {
  commit('setPackingListsQueryParams', payload);
};

const resetPackingListsQueryParams = ({ commit }) => {
  commit('setPackingListsQueryParams', {
    pageNum: 1,
    pageSize: 25,
    sortBy: 'datum',
    sortDesc: true,
    query: null,
    customerId: null,
  });
};

const refreshPackingListsTablePagination = ({ getters, commit }) => {
  const {
    packingLists,
    packingListsQueryParams: { pageNum, pageSize },
  } = getters;
  commit('tablePagination/setCurrentPage', pageNum, { root: true });
  commit('tablePagination/setNextButtonDisabledState', packingLists.length < pageSize, {
    root: true,
  });
};

const sortPackingListsTable = ({ dispatch, getters }, key) => {
  const { packingListsTableHeadings } = getters;
  if (packingListsTableHeadings[key].sortBy) {
    dispatch('packingListsSortDesc', key);
  } else {
    dispatch('packingListsSortBy', key);
  }
};

const packingListsSortDesc = ({ commit, dispatch, getters }, key) => {
  commit('setPackingListsTableHeadings', { type: 'desc', key });
  const { packingListsTableHeadings } = getters;
  dispatch('setPackingListsQueryParams', {
    sortDesc: packingListsTableHeadings[key].sortDesc,
  });
  dispatch('getPackingLists');
};

const packingListsSortBy = ({ commit, dispatch }, key) => {
  commit('setPackingListsTableHeadings', { type: 'by', key });
  dispatch('setPackingListsQueryParams', {
    sortDesc: true,
    sortBy: key,
  });
  dispatch('getPackingLists');
};

const searchPackingLists = ({ dispatch }, searchQuery) => {
  dispatch('tablePagination/reset', null, { root: true });
  const query = searchQuery || null;
  dispatch('setPackingListsQueryParams', { query, pageNum: 1 });
  dispatch('getPackingLists');
};

const getPackingList = async ({ commit, dispatch }, id) => {
  try {
    commit('setPackingListLoading', true);
    const {
      data: { stavke },
    } = await packingListsApi.getPackingList({ id });
    if (stavke) {
      commit('setPackingList', stavke);
      dispatch('packingListChangePage');
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setPackingListLoading', false);
  }
};

const setPackingListInfo = ({ commit }, payload) => {
  commit('setPackingListInfo', payload);
};

const packingListChangePage = ({ getters, commit, rootGetters }) => {
  const {
    packingListQueryParams: { pageSize },
  } = getters;
  const pageNum = rootGetters['tablePagination/currentPage'];
  const { packingList } = getters;
  const pageData = packingList.slice((pageNum - 1) * pageSize, pageNum * pageSize);
  commit('setPackingListPageData', pageData);
  commit('tablePagination/setNextButtonDisabledState', pageData.length < pageSize, {
    root: true,
  });
};

export default {
  getPackingLists,
  setPackingListsQueryParams,
  resetPackingListsQueryParams,
  refreshPackingListsTablePagination,
  sortPackingListsTable,
  packingListsSortDesc,
  packingListsSortBy,
  searchPackingLists,
  getPackingList,
  setPackingListInfo,
  packingListChangePage,
};
