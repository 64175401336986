<template>
  <div class="responses app__page">
    <div class="responses__actions app__page__actions">
      <v-input
        input-placeholder="Pretraži"
        icon-file-name="search.png"
        @value-change="inputChange"
        @icon-clicked="search"
        @keyup.native.enter="search"
      />
    </div>
    <v-table
      class="responses__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @heading-clicked="tableHeadingClick"
      @page-changed="pageChanged"
      show-pagination
      sortable
    >
      <responses-table-row
        v-for="response in responses"
        :response-data="response"
        :key="response.id"
        @click.native="responseClick(response)"
      />
    </v-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VInput from '@/components/core/VInput.vue';
import VTable from '@/components/table/VTable.vue';
import ResponsesTableRow from '@/components/responses/ResponsesTableRow.vue';

export default {
  name: 'Responses',
  components: {
    VInput,
    VTable,
    ResponsesTableRow,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      responses: 'responses/responses',
      loading: 'responses/responsesLoading',
      allTableHeadings: 'responses/responsesTableHeadings',
      queryParams: 'responses/responsesQueryParams',
      userRole: 'user/userRole',
    }),
    tableHeadings() {
      if (this.$store.state.user.user.portalID === '0') {
        return this.allTableHeadings;
      }

      const tableHeadingsWithoutProducer = {};
      Object.keys(this.allTableHeadings).forEach((key) => {
        if (key === 'dobavljac') {
          return;
        }

        tableHeadingsWithoutProducer[key] = this.allTableHeadings[key];
      });

      return tableHeadingsWithoutProducer;
    },
  },
  activated() {
    this.$store.dispatch('responses/refreshResponsesTablePagination');
  },
  methods: {
    tableHeadingClick(key) {
      this.$store.dispatch('responses/sortResponsesTable', key);
    },
    inputChange(val) {
      this.searchQuery = val;
    },
    search() {
      this.$store.dispatch('responses/searchResponses', this.searchQuery);
    },
    pageChanged() {
      this.$store.dispatch('responses/getResponses');
    },
    responseClick({ id, dobavljac, broj, datum, receiverId, senderId }) {
      this.$store.dispatch('tablePagination/setCurrentPage', 1);
      this.$store.dispatch('responses/setResponseInfo', {
        id,
        dobavljac,
        broj,
        datum,
        customerId: this.userRole === '1' ? receiverId : senderId,
      });
      this.$store.dispatch('responses/getResponse', id);
      this.$emit('switch-component', 'Response');
    },
  },
};
</script>

<style lang="scss" scoped>
.responses {
  padding: 10px;
  display: flex;
  flex-direction: column;
  &__actions {
    display: flex;
    align-items: flex-start;
    .v-input {
      margin-left: auto;
    }
  }
  &__table {
    margin-top: 12px;
  }
}
</style>
