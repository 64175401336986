const setSaldo = (state, payload) => {
  state.saldo = payload;
};

const setSaldoLoading = (state, payload) => {
  state.saldoLoading = payload;
};

export default {
  setSaldo,
  setSaldoLoading,
};
