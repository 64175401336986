<template>
  <div class="responses app__page">
    <keep-alive>
      <component :is="currentComponent" @switch-component="switchComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import Responses from '@/components/responses/Responses.vue';
import Response from '@/components/responses/Response.vue';

export default {
  name: 'ResponsesView',
  components: {
    Responses,
    Response,
  },
  data() {
    return {
      currentComponent: 'Responses',
    };
  },
  created() {
    this.$store.dispatch('tablePagination/reset');
    this.$store.dispatch('app/setPageTitle', 'Odgovori');
    this.$store.dispatch('responses/resetResponsesQueryParams');
    this.$store.dispatch('responses/getResponses');
  },
  methods: {
    switchComponent(componentName) {
      this.currentComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
