var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"v-button",class:[
    `v-button--${_vm.color}`,
    {
      'v-button--with-text': !!_vm.$slots.default,
      'v-button--without-icon': !_vm.iconFileName,
      'v-button--disabled': _vm.disabled,
    },
  ],style:({ width: `${_vm.width}`, height: `${_vm.height}` }),attrs:{"disabled":_vm.disabled,"type":_vm.buttonType},on:{"click":_vm.clicked}},[(_vm.loading)?[_c('v-loader',{attrs:{"size":18}})]:[(_vm.iconFileName)?_c('img',{staticClass:"v-button__icon",attrs:{"src":require(`@/assets/img/icons/button/${_vm.iconFileName}`),"alt":"ikona dugmeta"}}):_vm._e(),_c('span',{staticClass:"v-button__text"},[_vm._t("default")],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }