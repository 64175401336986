<template>
  <div
    class="v-table__body__row orders-table-row"
    :class="[
      {
        read: orderData.userRead,
        processed: orderData.userProcessed,
      },
    ]"
  >
    <div v-if="this.$store.state.user.user.portalID === '0'"
      class="v-table__body__row__data orders-table-row__data--first-column"
    >
      <span class="orders-table-row__data--first-column__content">
        {{ orderData.dobavljac }}
      </span>
    </div>
    <div class="v-table__body__row__data">{{ orderData.broj }}</div>
    <div class="v-table__body__row__data">{{ orderData.datum | formatDate }}</div>
  </div>
</template>

<script>
export default {
  name: 'OrdersTableRow',
  props: {
    orderData: {
      type: Object,
      requred: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.orders-table-row {
  font-weight: 700;
  &:hover {
    background-color: #e6e6e6;
  }
  &.processed {
    font-weight: 400;
  }
  &.read {
    background-color: #e6e6e6;
  }
}
</style>
