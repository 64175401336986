<template>
  <div class="v-input" :style="{ width }">
    <div class="v-input__input-wrapper" :class="{ 'v-input__input-wrapper--error': !valid }">
      <input
        @input="input"
        ref="input"
        :value="value"
        :type="inputType"
        :placeholder="inputPlaceholder"
        :disabled="inputDisabled"
        :autocomplete="inputAutocomplete"
      />
      <img
        class="v-input__input-wrapper__icon"
        :src="require(`@/assets/img/icons/input/${iconFileName}`)"
        alt="ikona input polja"
        v-if="iconFileName"
        height="15"
        width="15"
        @click="iconClicked"
      />
    </div>
    <div class="v-input__error-wrapper" v-if="!valid">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'VInput',
  props: {
    inputType: {
      type: String,
      required: false,
      default: 'text',
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: '',
    },
    inputAutocomplete: {
      type: String,
      required: false,
      default: 'off',
    },
    valid: {
      type: Boolean,
      required: false,
      default: true,
    },
    errorMessage: {
      type: String,
      required: false,
      default: '',
    },
    iconFileName: {
      type: String,
      required: false,
      default: '',
    },
    width: {
      type: String,
      required: false,
      default: '220px',
    },
    inputDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: this.inputType === 'number' ? 0 : '',
    };
  },
  methods: {
    input(e) {
      this.value = e.target.value;
      this.value = this.inputType === 'number' ? Number(this.value) : this.value;
      this.$emit('value-change', this.value);
    },
    iconClicked() {
      this.$emit('icon-clicked');
    },
    focus() {
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    blur() {
      this.$nextTick(() => {
        this.$refs.input.blur();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.v-input {
  height: 25px;
  position: relative;
  &__input-wrapper {
    display: flex;
    align-items: center;
    height: 100%;
    border: 1px #cccccc solid;
    &--error {
      border-color: #e64c3d;
    }
    input {
      flex: 1;
      max-width: 100%;
      height: 100%;
      color: #999999;
      padding: 0 0 0 4px;
      border: none;
      outline: none;
    }
    &__icon {
      cursor: pointer;
      margin-right: 4px;
    }
  }
  &__error-wrapper {
    position: absolute;
    bottom: -15px;
    left: 0;
    color: #e64c3d;
    font-size: 10px;
    padding-left: 4px;
  }
}
</style>
