<template>
  <div class="v-table__body__row products-table-row">
    <div
      v-for="(item, key) in newOrderTableColumns"
      :key="key"
      class="v-table__body__row__data products-table-row__data"
      :style="{ flex: item.columnWidth || '1' }"
    >
      {{ cellDisplayValue(key) }}
    </div>
    <div class="v-table__body__row__data products-table-row__data products-table-row__data--amount">
      <v-button
        @clicked="minusClick"
        :disabled="newOrderItemData.amount === 1"
        width="20px"
        height="20px"
      >
        -
      </v-button>
      <v-input
        :ref="newOrderItemData.code"
        input-type="number"
        width="40px"
        @value-change="inputChange"
      />
      <v-button @clicked="plusClick" width="20px" height="20px">+</v-button>
      <v-button @clicked="removeFromNewOrder" width="20px" height="20px">&#215;</v-button>
    </div>
  </div>
</template>

<script>
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';

export default {
  name: 'NewOrderTableRow',
  components: {
    VButton,
    VInput,
  },
  props: {
    newOrderItemData: {
      type: Object,
      requred: true,
    },
    supplierId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    allColumns() {
      return this.$store.getters['createOrder/productsTableHeadings'];
    },
    newOrderTableColumns() {
      const tableHeadings = {};
      Object.keys(this.allColumns).forEach((key) => {
        const { exists } = this.allColumns[key];
        if (key !== 'amount' && ((typeof exists === 'boolean' && exists) || exists.includes(this.supplierId))) {
          tableHeadings[key] = this.allColumns[key];
        }
      });
      return tableHeadings;
    },
  },
  mounted() {
    this.$refs[this.newOrderItemData.code].value = this.newOrderItemData.amount;
  },
  methods: {
    cellDisplayValue(key) {
      if (key === 'available') {
        if (typeof this.newOrderItemData.available === 'boolean') {
          return this.newOrderItemData.available ? 'Da' : 'Ne';
        }
        return this.newOrderItemData.available;
      }
      if (key === 'discount') {
        if (!this.newOrderItemData.discount) {
          return '-';
        }
        return this.supplierId === 9 && this.newOrderItemData.worthiness ? `${this.newOrderItemData.discount}*` : this.newOrderItemData.discount;
      }
      if (key === 'taxRate' || key === 'price') {
        return parseFloat(this.newOrderItemData[key]).toFixed(2) || '-';
      }
      return this.newOrderItemData[key] || '-';
    },
    inputChange(val) {
      this.$refs[this.newOrderItemData.code].value = val;
      this.itemAmountChange(this.$refs[this.newOrderItemData.code].value);
    },
    removeFromNewOrder() {
      const { code } = this.newOrderItemData;
      this.$store.dispatch('createOrder/removeItemFromNewOrder', {
        code,
        addToLocalStorage: !this.$route.params.id,
      });
    },
    minusClick() {
      this.$refs[this.newOrderItemData.code].value -= 1;
      this.itemAmountChange(this.$refs[this.newOrderItemData.code].value);
    },
    plusClick() {
      this.$refs[this.newOrderItemData.code].value += 1;
      this.itemAmountChange(this.$refs[this.newOrderItemData.code].value);
    },
    itemAmountChange(newAmount) {
      const { code } = this.newOrderItemData;
      this.$store.dispatch('createOrder/newOrderItemAmountChange', {
        payload: { newAmount, code },
        addToLocalStorage: !this.$route.params.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.products-table-row {
  &:hover {
    background-color: #e6e6e6;
  }
  &__data {
    &--amount {
      display: flex;
      .v-button,
      .v-input {
        margin-right: 10px;
      }
      .v-input {
        height: 20px;
      }
    }
  }
}
</style>
