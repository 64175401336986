<template>
  <div class="create-order app__page">
    <div class="create-order__actions">
      <div class="create-order__actions__top">
        <v-button @clicked="goBack" icon-file-name="back-arrow.png" width="25px" height="35px" />
        <div class="create-order__actions__top__select">
          <v-select
            :options="customersLocations"
            :default="defaultCustomerLocationSelectValue"
            @input="selectCustomerLocation"
            :disabled="!!$route.params.id"
          />
          <v-select
            v-if="customersSuppliers.length > 2"
            :options="customersSuppliers"
            :default="defaultCustomerSupplierSelectValue"
            @input="selectCustomerSupplier"
            :disabled="!customerId || !!$route.params.id || !!newOrder.length"
          />
        </div>
        <div class="create-order__actions__top__date">
          <div>Datum narudžbine:</div>
          <div>{{ new Date() | formatDate }}</div>
        </div>
      </div>
      <div class="create-order__actions__bottom">
        <div v-if="supplierId === null || customerId === null">{{ productsPlaceholderText }}</div>
        <div v-else class="create-order__actions__bottom__input-wrapper">
          <div
            v-if="tenderOrderAllowed"
            class="create-order__actions__bottom__input-wrapper__checkbox-wrapper"
          >
            <div
              class="checkbox"
              :class="{ 'checkbox--selected': tenderOrder }"
              @click="checkboxClick"
            ></div>
            <div>Tenderska narudžbina</div>
          </div>
          <v-input
            ref="searchInput"
            input-placeholder="Pretraži"
            icon-file-name="search.png"
            @value-change="inputChange"
            @icon-clicked="search"
            @keyup.native.enter="search"
          />
        </div>
      </div>
    </div>
    <v-table
      class="create-order__table"
      :loading="productsLoading"
      :table-headings="productsTableHeadings"
      @heading-clicked="tableHeadingClick"
      @page-changed="pageChanged"
      show-pagination
      sortable
      tabindex="0"
      @keyup.native.self.up="keyPress(-1)"
      @keyup.native.self.down="keyPress(1)"
      @keyup.native.self.enter="editProductRow"
      ref="productsTable"
    >
      <products-table-row
        v-for="rowData in products"
        :product-data="rowData"
        :key="rowData.code"
        :supplier-id="supplierId"
        @product-added="$refs.searchInput.focus()"
        @blur-row="focusTable"
      />
    </v-table>
    <new-order
      class="create-order__new-order"
      :class="{ 'push-up': !!newOrder.length }"
      :supplier-id="supplierId"
      @minimized="newOrderDrawerMinimized"
      v-if="!!products.length"
    />
    <div class="create-order__save" v-if="!!newOrder.length">
      <div class="create-order__save__actions">
        <v-button @clicked="cancelNewOrder" icon-file-name="cancel-icon.png" width="95px">
          Odbaci
        </v-button>
        <v-button
          @clicked="saveOrder"
          icon-file-name="save-icon.png"
          width="95px"
          :loading="loadingSaveOrder"
        >
          Sačuvaj
        </v-button>
        <v-button
          @clicked="sendOrder"
          icon-file-name="posalji.png"
          width="95px"
          v-if="showSendOrderButton"
          :loading="loadingSendOrder"
          :disabled="!sendOrderAllowed"
        >
          Pošalji
        </v-button>
      </div>
      <div class="create-order__save__amount" v-if="supplierId === 1645">
        Ukupan iznos bez PDV: <span>{{ parseFloat(totalAmount).toFixed(2) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';
import VSelect from '@/components/core/VSelect.vue';
import VTable from '@/components/table/VTable.vue';
import ProductsTableRow from '@/components/products/ProductsTableRow.vue';
import NewOrder from '@/components/createOrder/NewOrder.vue';

export default {
  name: 'CreateOrderView',
  components: {
    VButton,
    VInput,
    VSelect,
    VTable,
    ProductsTableRow,
    NewOrder,
  },
  data() {
    return {
      searchQuery: '',
      supplierId: null,
      customerId: null,
      showSendOrderButton: false,
      defaultCustomerLocationSelectValue: null,
      defaultCustomerSupplierSelectValue: null,
      savedOrderId: null,
      tenderOrder: false,
      sendOrderAllowed: true,
    };
  },
  computed: {
    ...mapGetters({
      userId: 'user/userId',
      customersSuppliers: 'user/customersSuppliers',
      customersLocations: 'user/customersLocationsForOrder',
      createOrderActions: 'user/createOrderActions',
      products: 'createOrder/products',
      productsLoading: 'createOrder/productsLoading',
      allProductsTableHeadings: 'createOrder/productsTableHeadings',
      newOrder: 'createOrder/newOrder',
      isOrderInLocalStorage: 'createOrder/isOrderInLocalStorage',
      loadingSaveOrder: 'createOrder/loadingSaveOrder',
      loadingSendOrder: 'createOrder/loadingSendOrder',
    }),
    productsPlaceholderText() {
      return this.customerId
        ? 'Izaberite dobavljača iznad kako biste videli artikle.'
        : 'Izaberite isporučno mesto da biste mogli da izaberete dobavljača.';
    },
    tenderOrderAllowed() {
      return this.createOrderActions.find((action) => action.name === 'allow_tender_order');
    },
    productsTableHeadings() {
      const tableHeadings = {};
      Object.keys(this.allProductsTableHeadings).forEach((key) => {
        const { exists } = this.allProductsTableHeadings[key];
        if ((typeof exists === 'boolean' && exists) || exists.includes(this.supplierId)) {
          tableHeadings[key] = this.allProductsTableHeadings[key];
        }
      });
      return tableHeadings;
    },
    totalAmount() {
      return this.newOrder.reduce((prev, curr) => (
        prev + (curr.price * curr.amount * (1 - Number(curr.rabat) / 100))
      ), 0);
    },
  },
  async created() {
    this.$store.dispatch('app/setPageTitle', 'Nova narudžbina');

    this.$store.dispatch('tablePagination/reset');
    this.$store.dispatch('createOrder/resetProductsQueryParams');

    if (this.$route.params.id) {
      const { senderId, receiverId, sendOrderAllowed } = await this.$store.dispatch(
        'createOrder/getSavedOrder',
        this.$route.params.id,
      );

      this.supplierId = receiverId;
      this.customerId = senderId;
      this.sendOrderAllowed = sendOrderAllowed;
      this.defaultCustomerLocationSelectValue = this.customersLocations.find(
        (item) => item.id === this.customerId,
      );
      this.defaultCustomerSupplierSelectValue = this.customersSuppliers.find(
        (item) => item.id === this.supplierId,
      );
      this.selectCustomerSupplier({ id: this.supplierId });
      this.showSendOrderButton = true;
    } else if (this.isOrderInLocalStorage(this.userId)) {
      const { senderId, receiverId } = await this.$store.dispatch(
        'createOrder/getLocalStorageOrder',
      );
      this.supplierId = receiverId;
      this.customerId = senderId;
      this.defaultCustomerLocationSelectValue = this.customersLocations.find(
        (item) => item.id === senderId,
      );
      await this.selectCustomerLocation({ id: senderId });
      this.defaultCustomerSupplierSelectValue = this.customersSuppliers.find(
        (item) => item.id === receiverId,
      );
      this.selectCustomerSupplier({ id: receiverId });
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    async selectCustomerSupplier({ id }) {
      if (id) {
        if (!this.$route.params.id) {
          this.$store.dispatch('createOrder/saveToLocalStorage', { receiverId: id });
        }
        this.supplierId = id;
        await this.$store.dispatch('createOrder/getProducts', { supplierId: id, customerId: this.customerId });
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    async selectCustomerLocation({ id }) {
      if (id) {
        this.$store.dispatch('tablePagination/reset');
        this.$store.dispatch('createOrder/resetProductsQueryParams');
        this.defaultCustomerSupplierSelectValue = null;
        if (!this.$route.params.id) {
          this.$store.dispatch('createOrder/saveToLocalStorage', { senderId: id });
        }
        await this.$store.dispatch('user/getCustomersSuppliers', id);
        this.customerId = id;
        if (this.customersSuppliers.length === 2) {
          const [, supplier] = this.customersSuppliers;
          this.defaultCustomerSupplierSelectValue = supplier;
          this.supplierId = supplier.id;
          await this.selectCustomerSupplier({ id: this.supplierId });
          // this.showSendOrderButton = true;
        } else {
          const [supplier] = this.customersSuppliers;
          this.defaultCustomerSupplierSelectValue = supplier;
          this.supplierId = supplier.id;
          this.$store.dispatch('createOrder/clearProducts');
        }
      }
    },
    tableHeadingClick(key) {
      this.$store.dispatch('createOrder/sortProductsTable', { key, supplierId: this.supplierId, customerId: this.customerId });
    },
    pageChanged() {
      this.$store.dispatch('createOrder/getProducts', { supplierId: this.supplierId, customerId: this.customerId });
    },
    inputChange(val) {
      this.searchQuery = val;
    },
    async search() {
      await this.$store.dispatch('createOrder/searchProducts', {
        searchQuery: this.searchQuery,
        supplierId: this.supplierId,
        customerId: this.customerId,
      });
      this.$nextTick(() => {
        this.focusTable();
      });
    },
    keyPress(value) {
      this.$store.dispatch('createOrder/switchProductsRow', value);
    },
    editProductRow() {
      this.$store.dispatch('createOrder/editProductRow', true);
    },
    focusTable() {
      this.$refs.productsTable.$el.focus();
    },
    cancelNewOrder() {
      if (!this.$route.params.id) {
        this.$store.dispatch('createOrder/clearNewOrder');
        this.$store.dispatch('createOrder/removeFromLocalStorage');
      }
      this.$router.go(-1);
    },
    async saveOrder() {
      if (this.$route.params.id) {
        this.saveOrderChanges();
      } else {
        await this.saveNewOrder();
        this.$store.dispatch('createOrder/removeFromLocalStorage');
      }
    },
    async saveNewOrder() {
      const savedOrder = await this.$store.dispatch('createOrder/saveNewOrder', {
        items: this.newOrder,
        receiverCustomerId: this.supplierId,
        senderCustomerId: this.customerId,
        isTenderOrder: this.tenderOrder,
      });

      this.showSendOrderButton = true;
      this.savedOrderId = savedOrder.data.id;
    },
    saveOrderChanges() {
      this.$store.dispatch('createOrder/saveOrderChanges', {
        items: this.newOrder,
        orderId: this.$route.params.id,
        isTenderOrder: this.tenderOrder,
      });
    },
    sendOrder() {
      if (this.$route.params.id) {
        this.$store.dispatch('createOrder/sendOrder', {
          id: this.$route.params.id,
        });
      } else if (this.savedOrderId) {
        this.$store.dispatch('createOrder/sendOrder', {
          id: this.savedOrderId,
        });
      }
    },
    newOrderDrawerMinimized() {
      this.focusTable();
    },
    checkboxClick() {
      this.tenderOrder = !this.tenderOrder;
    },
  },
  beforeDestroy() {
    this.$store.dispatch('createOrder/clearProducts');
    this.$store.dispatch('createOrder/clearNewOrder');
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  &--selected {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    color: #99a1a7;
    &::after {
      content: '\2714';
      font-size: 10px;
      position: absolute;
      top: 0px;
      left: 2px;
      color: #0077cc;
    }
  }
}
.create-order {
  display: flex;
  flex-direction: column;
  position: relative;
  &__actions {
    padding: 10px 10px 0;
    &__top {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      &__select {
        display: flex;
        margin-left: 10px;
        width: 600px;
        .v-select {
          &:first-of-type {
            margin-right: 20px;
          }
          &:last-of-type {
            min-width: 150px;
          }
        }
      }
      &__date {
        margin-left: auto;
        text-align: right;
        color: #666666;
      }
    }
    &__bottom {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      &__input-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        &__checkbox-wrapper {
          display: flex;
          align-items: center;
          .checkbox {
            margin-right: 6px;
          }
        }
        .v-input {
          margin-left: auto;
        }
      }
    }
  }
  &__table {
    padding: 0 10px 10px;
    margin-top: 12px;
    outline: 0;
  }
  &__new-order {
    &.push-up {
      margin-bottom: 45px;
    }
  }
  &__save {
    padding: 0 10px;
    background-color: #cccccc;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    &__actions {
      display: flex;
      align-items: center;
      .v-button {
        margin-right: 10px;
      }
    }
    &__amount {
      span {
        border: 1px solid #000;
        padding: 1px 3px;
        font-weight: 600;
      }
    }
  }
}
</style>
