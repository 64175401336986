import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  visible: false,
  text: '',
  status: 'info', // info, success, warning, error
  timeout: null,
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
