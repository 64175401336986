<template>
  <div class="v-select" :tabindex="tabindex" @blur="blur">
    <div
      class="v-select__selected"
      :class="{ 'v-select__selected--open': open, 'v-select__selected--disabled': disabled }"
      @click="toggleOpen"
    >
      {{ selected.name }} {{ selected.description && `- ${selected.description}` }}
      {{ selected.locationCode && `- ${selected.locationCode}` }}
    </div>
    <div class="v-select__items" :class="{ 'v-select__items--select-hide': !open }">
      <div v-for="option of filteredOptions" :key="option.id" @click="select(option)">
        {{ option.name }} {{ option.description && `- ${option.description}` }}
        {{ option.locationCode && `- ${option.locationCode}` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    options: {
      type: Array,
      required: true,
    },
    default: {
      type: Object,
      required: false,
      default: null,
    },
    tabindex: {
      type: Number,
      required: false,
      default: 0,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      selected: {
        name: '',
      },
      open: false,
    };
  },
  computed: {
    filteredOptions() {
      return this.options.filter((item) => !item.nonSelectable);
    },
  },
  mounted() {
    if (this.default) {
      this.selected = this.default;
    } else if (this.options.length > 0) {
      [this.selected] = this.options;
    }
    this.$emit('input', this.selected);
  },
  watch: {
    default(val) {
      if (val) {
        this.selected = val;
      }
    },
  },
  methods: {
    blur() {
      this.open = false;
    },
    toggleOpen() {
      if (this.disabled) return;
      this.open = !this.open;
    },
    select(option) {
      this.selected = option;
      this.open = false;
      this.$emit('input', option);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 30px;
  line-height: 30px;
  &__selected {
    background-color: #e6e6e6;
    border: 1px solid #cccccc;
    color: #000000;
    font-weight: 700;
    padding-left: 5px;
    padding-right: 30px;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    &::after {
      position: absolute;
      content: '';
      top: 14px;
      right: 15px;
      width: 0;
      height: 0;
      border: 5px solid transparent;
      border-color: #000000 transparent transparent transparent;
    }
    &--open {
      &::after {
        top: 9px;
        border-color: transparent transparent #000 transparent;
      }
    }
    &--disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
  &__items {
    max-height: calc(100vh - 330px);
    overflow-y: auto;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #cccccc;
    border-bottom: 1px solid #cccccc;
    position: absolute;
    background-color: #e6e6e6;
    left: 0;
    right: 0;
    z-index: 1;
    &--select-hide {
      display: none;
    }
    div {
      color: #000000;
      padding-left: 5px;
      cursor: pointer;
      user-select: none;
      &:hover {
        background-color: #2c2c2c;
        color: #ffffff;
      }
    }
  }
}
</style>
