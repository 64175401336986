import Api from '../Api';

export default class Orders extends Api {
  async getOrders({ pageNum, pageSize, sortBy, sortDesc, query, customerId }) {
    let url = `/documents/orders?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;
    if (query) {
      url = `${url}&query=${query}`;
    }
    if (customerId) {
      url = `${url}&customerId=${customerId}`;
    }
    const response = await this.get(url);
    return response;
  }

  async getOrder({ id }) {
    const url = `/documents/orders/${id}`;
    const response = await this.get(url);
    return response;
  }

  async createNewOrder(payload) {
    const url = '/documents/orders';
    const response = await this.post(url, payload);
    return response;
  }

  async changeOrder({ id, payload }) {
    const url = `/documents/orders/${id}`;
    const response = await this.put(url, payload);
    return response;
  }

  async sendOrder({ id }) {
    const url = `/documents/orders/send/${id}`;
    const response = await this.post(url);
    return response;
  }
}
