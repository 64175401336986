<template>
  <header class="top-bar">
    <div class="top-bar__left-side">
      <div
        class="top-bar__left-side__logo-wrapper"
        :style="{ backgroundColor: backgroundColorPrimary }">
        <img height="35" :src="logo" alt="logo kompanije" />
      </div>
    </div>
    <div class="top-bar__right-side" :style="{ backgroundColor: backgroundColorSecondary }">
      <div
        class="top-bar__right-side__title-wrapper"
        :style="{ color: textColor }" v-if="pageTitle"
      >
        {{ pageTitle }}
      </div>
      <div class="top-bar__right-side__logout-wrapper" v-if="user">
        <div
          class="top-bar__right-side__logout-wrapper__item"
          :style="{ color: textColor }"
        >
          {{ user.username }}
        </div>
        <div
          class="top-bar__right-side__logout-wrapper__item top-bar__logout-wrapper__item--logout"
          :style="{ color: textColor }"
          @click="logout"
        >
          Odjavi se
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheTopBar',
  props: {
    logo: {
      type: String,
      required: true,
    },
    backgroundColorPrimary: {
      type: String,
      required: true,
    },
    backgroundColorSecondary: {
      type: String,
      required: true,
    },
    textColor: {
      type: String,
      default: 'ffffff',
    },
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      pageTitle: 'app/pageTitle',
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout');
      this.$router.push('/login');
    },
  },
};
</script>

<style lang="scss" scoped>
.top-bar {
  height: 55px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  &__left-side {
    &__logo-wrapper {
      width: 235px;
      height: 100%;
      box-shadow: 3px 0 5px -5px #000000;
      display: flex;
      align-items: center;
      padding-left: 14px;
    }
  }
  &__right-side {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    &__title-wrapper {
      padding-left: 15px;
      margin-top: 15px;
      font-size: 22px;
      font-weight: bold;
    }
    &__logout-wrapper {
      margin-right: 50px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      &__item {
        font-size: 14px;
        font-weight: bold;
        &--logout {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
