import Api from '../Api';

export default class Invoices extends Api {
  async getInvoices({ pageNum, pageSize, sortBy, sortDesc, query, customerId }) {
    let url = `/documents/invoices?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;
    if (query) {
      url = `${url}&query=${query}`;
    }
    if (customerId) {
      url = `${url}&customerId=${customerId}`;
    }
    const response = await this.get(url);
    return response;
  }

  async getInvoice({ id }) {
    const url = `/documents/invoices/${id}`;
    const response = await this.get(url);
    return response;
  }

  async missingInvoice(payload) {
    const url = '/documents/invoices/missing';
    const response = await this.post(url, payload);
    return response;
  }

  async resendDocument({ id }) {
    const url = `/documents/resend/${id}`;
    const response = await this.post(url);
    return response;
  }

  async downloadXml({ id }) {
    const url = `/documents/download/${id}`;
    const response = await this.get(url, true);
    return response;
  }

  async downloadPdf({ id }) {
    const url = `/documents/pdf/${id}`;
    const response = await this.get(url, true);
    return response;
  }
}
