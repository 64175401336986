import Api from '../Api';

export default class User extends Api {
  async login(payload) {
    const url = '/auth/login';
    const response = await this.publicPost(url, payload);
    return response;
  }

  async changePassword(payload) {
    const url = '/auth/changePassword';
    const response = await this.post(url, payload);
    return response;
  }

  async getUserActions() {
    const url = '/actions/main';
    const response = await this.get(url);
    return response;
  }

  async getInvoicesActions() {
    const url = '/actions/invoice_list';
    const response = await this.get(url);
    return response;
  }

  async getInvoiceActions() {
    const url = '/actions/invoice_details';
    const response = await this.get(url);
    return response;
  }

  async getOrdersActions() {
    const url = '/actions/order_list';
    const response = await this.get(url);
    return response;
  }

  async getCreateOrderActions() {
    const url = '/actions/create_order';
    const response = await this.get(url);
    return response;
  }

  async addCode(payload) {
    const url = '/items/addCode';
    const response = await this.post(url, payload);
    return response;
  }

  async getCustomersLocations() {
    const url = '/customers/locations';
    const response = await this.get(url);
    return response;
  }

  async getCustomersSuppliers({ customerId }) {
    let url = '/customers/suppliers';
    if (customerId) {
      url = `${url}/${customerId}`;
    }
    const response = await this.get(url);
    return response;
  }
}
