import InvoicesApi from '../../api/invoices/index';
import UserApi from '../../api/user/index';

const invoicesApi = new InvoicesApi();
const userApi = new UserApi();

const downloadFile = async (fileContent, fileName) => {
  const fileType = '.xml';
  const a = document.createElement('a');
  a.download = fileName;
  a.href = URL.createObjectURL(fileContent);
  a.dataset.downloadurl = [fileType, a.download, a.href].join(':');
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  setTimeout(() => { URL.revokeObjectURL(a.href); }, 1500);
};

const getInvoices = async ({ commit, getters, rootGetters }) => {
  try {
    commit('setInvoicesLoading', true);
    const pageNum = rootGetters['tablePagination/currentPage'];
    const customerId = rootGetters['user/customerId'];
    commit('setInvoicesQueryParams', { pageNum, customerId });
    const { invoicesQueryParams } = getters;
    const { pageSize } = invoicesQueryParams;
    const {
      data: { documents, total },
    } = await invoicesApi.getInvoices(invoicesQueryParams);
    commit('tablePagination/setNextButtonDisabledState', total < pageSize, { root: true });
    commit('setInvoices', documents);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setInvoicesLoading', false);
  }
};

const setInvoicesQueryParams = ({ commit }, payload) => {
  commit('setInvoicesQueryParams', payload);
};

const resetInvoicesQueryParams = ({ commit }) => {
  commit('setInvoicesQueryParams', {
    pageNum: 1,
    pageSize: 25,
    sortBy: 'datum',
    sortDesc: true,
    query: null,
    customerId: null,
  });
};

const refreshInvoicesTablePagination = ({ getters, commit }) => {
  const {
    invoices,
    invoicesQueryParams: { pageNum, pageSize },
  } = getters;
  commit('tablePagination/setCurrentPage', pageNum, { root: true });
  commit('tablePagination/setNextButtonDisabledState', invoices.length < pageSize, { root: true });
};

const sortInvoicesTable = ({ dispatch, getters }, key) => {
  const { invoicesTableHeadings } = getters;
  if (invoicesTableHeadings[key].sortBy) {
    dispatch('invoicesSortDesc', key);
  } else {
    dispatch('invoicesSortBy', key);
  }
};

const invoicesSortDesc = ({ commit, dispatch, getters }, key) => {
  commit('setInvoicesTableHeadings', { type: 'desc', key });
  const { invoicesTableHeadings } = getters;
  dispatch('setInvoicesQueryParams', {
    sortDesc: invoicesTableHeadings[key].sortDesc,
  });
  dispatch('getInvoices');
};

const invoicesSortBy = ({ commit, dispatch }, key) => {
  commit('setInvoicesTableHeadings', { type: 'by', key });
  dispatch('setInvoicesQueryParams', {
    sortDesc: true,
    sortBy: key,
  });
  dispatch('getInvoices');
};

const searchInvoices = ({ dispatch }, searchQuery) => {
  dispatch('tablePagination/reset', null, { root: true });
  const query = searchQuery || null;
  dispatch('setInvoicesQueryParams', { query, pageNum: 1 });
  dispatch('getInvoices');
};

const setMissingInvoiceModal = async ({ commit }, payload) => {
  commit('setMissingInvoiceModal', payload);
};

const doInvoicesAction = async ({ commit, dispatch }, { actionName }) => {
  if (actionName === 'missing_invoice') {
    try {
      commit('setMissingInvoiceModalLoading', true);
      commit('setMissingInvoiceModal', true);
      await dispatch('user/getCustomersSuppliers', null, { root: true });
    } catch (error) {
      console.log(error.response);
    } finally {
      commit('setMissingInvoiceModalLoading', false);
    }
  }
};

const submitMissingInvoice = async ({ commit }, payload) => {
  try {
    commit('setMissingInvoiceModalLoading', true);
    await invoicesApi.missingInvoice(payload);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setMissingInvoiceModalLoading', false);
    commit('setMissingInvoiceModal', false);
  }
};

const setFirstColumnTitle = ({ commit }, userRole) => {
  const title = userRole === '1' ? 'Dobavljač' : 'Kupac';
  commit('setFirstColumnTitle', title);
};

const getInvoice = async ({ commit, dispatch }, id) => {
  try {
    commit('setInvoiceLoading', true);
    const {
      data: { stavke },
    } = await invoicesApi.getInvoice({ id });
    if (stavke) {
      const data = stavke.map((item) => ({
        ...item,
        loading: false,
      }));
      commit('setInvoiceReadOnClient', id);
      commit('setInvoice', data);
      commit('setFilteredInvoice', data);
      dispatch('invoiceChangePage');
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setInvoiceLoading', false);
  }
};

const setInvoiceInfo = ({ commit }, payload) => {
  commit('setInvoiceInfo', payload);
};

const searchInvoice = ({ getters, commit }, searchQuery) => {
  const { invoice } = getters;
  const filteredData = invoice.filter((item) => item.ean.includes(searchQuery));
  commit('setFilteredInvoice', filteredData);
  commit('setInvoicePageData', filteredData);
};

const invoiceChangePage = ({ getters, commit, rootGetters }) => {
  const {
    invoiceQueryParams: { pageSize },
  } = getters;
  const pageNum = rootGetters['tablePagination/currentPage'];
  const { filteredInvoice } = getters;
  const pageData = filteredInvoice.slice((pageNum - 1) * pageSize, pageNum * pageSize);
  commit('setInvoicePageData', pageData);
  commit('tablePagination/setNextButtonDisabledState', pageData.length < pageSize, {
    root: true,
  });
};

const doInvoiceAction = async (context, { actionName, id }) => {
  if (actionName === 'resend_doc') {
    try {
      const response = await invoicesApi.resendDocument({ id });
      return response;
    } catch (error) {
      console.log(error.response);
    }
  }
  if (actionName === 'download_xml') {
    try {
      const { data, headers } = await invoicesApi.downloadXml({ id });
      const header = headers['content-disposition'];
      const parts = header.split(';');
      const filename = parts[1].split('=')[1].replaceAll('"', '');
      await downloadFile(data, filename);
    } catch (error) {
      console.log(error);
      console.log(error.response);
    }
  }
  if (actionName === 'download_pdf') {
    try {
      const { data, headers } = await invoicesApi.downloadPdf({ id });
      const header = headers['content-disposition'];
      const parts = header.split(';');
      const filename = parts[1].split('=')[1].replaceAll('"', '');
      await downloadFile(data, filename);
    } catch (error) {
      console.log(error.response);
    }
  }
  return false;
};

const addCode = async ({ commit, getters }, payload) => {
  const {
    invoiceInfo: { customerId },
  } = getters;
  try {
    commit('setInvoiceItemsLoading', { items: payload, loading: true });
    await Promise.allSettled(
      payload.map((item) => {
        const data = {
          ...item,
          customerId,
        };
        return userApi.addCode(data);
      }),
    );
    // await dispatch('getInvoice', id);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setInvoiceItemsLoading', { items: payload, loading: false });
  }
};

export default {
  getInvoices,
  setInvoicesQueryParams,
  resetInvoicesQueryParams,
  refreshInvoicesTablePagination,
  sortInvoicesTable,
  setMissingInvoiceModal,
  invoicesSortDesc,
  invoicesSortBy,
  searchInvoices,
  doInvoicesAction,
  submitMissingInvoice,
  setFirstColumnTitle,
  getInvoice,
  setInvoiceInfo,
  searchInvoice,
  invoiceChangePage,
  doInvoiceAction,
  addCode,
};
