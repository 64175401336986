<template>
  <div class="invoices app__page">
    <div class="invoices__actions app__page__actions">
      <div class="invoices__actions__buttons-wrapper">
        <v-button
          v-for="(action, index) in invoicesActions"
          :class="{ 'margin-left': index !== 0 }"
          :key="action.id"
          @clicked="invoicesAction(action.name)"
          :icon-file-name="`${action.name}.png`"
          width="auto"
        >
          {{ action.buttonText }}
        </v-button>
      </div>
      <v-input
        input-placeholder="Pretraži"
        icon-file-name="search.png"
        @value-change="inputChange"
        @icon-clicked="search"
        @keyup.native.enter="search"
      />
    </div>
    <v-table
      class="invoices__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @heading-clicked="tableHeadingClick"
      @page-changed="pageChanged"
      show-pagination
      sortable
    >
      <invoices-table-row
        v-for="invoice in invoices"
        :invoice-data="invoice"
        :key="invoice.id"
        @click.native="invoiceClick(invoice)"
      />
    </v-table>
    <v-modal
      class="invoices__missing-invoice"
      v-if="missingInvoiceModal"
      title="Nedostaje faktura"
      :loading="missingInvoiceModalLoading"
      @close-modal="closeMissingInvoiceModal"
    >
      <template #content>
        <div class="invoices__missing-invoice__input-item">
          <div>Dobavljač:</div>
          <div class="invoices__missing-invoice__input-item__input">
            <v-select :options="customersSuppliers" @input="selectCustomerSupplier" />
          </div>
        </div>
        <div
          class="
            invoices__missing-invoice__input-item
            invoices__missing-invoice__input-item--invoice-number
          "
        >
          <div>Broj fakture:</div>
          <div class="invoices__missing-invoice__input-item__input">
            <v-input
              width="100%"
              input-placeholder="Faktura koja nedostaje"
              @value-change="missingInvoiceInputChange"
              @keyup.native.enter="submitMissingInvoice"
            />
          </div>
        </div>
      </template>
      <template #actions>
        <div class="invoices__missing-invoice__actions">
          <v-button
            @clicked="closeMissingInvoiceModal"
            icon-file-name="cancel-icon.png"
            width="95px"
          >
            Odbaci
          </v-button>
          <v-button
            @clicked="submitMissingInvoice"
            :disabled="submitMissingInoviceButtonDisabled"
            icon-file-name="save-icon.png"
            width="95px"
          >
            Pošalji
          </v-button>
        </div>
      </template>
    </v-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';
import VTable from '@/components/table/VTable.vue';
import VModal from '@/components/core/VModal.vue';
import VSelect from '@/components/core/VSelect.vue';
import InvoicesTableRow from '@/components/invoices/InvoicesTableRow.vue';

export default {
  name: 'Invoices',
  components: {
    VButton,
    VInput,
    VTable,
    VModal,
    VSelect,
    InvoicesTableRow,
  },
  data() {
    return {
      searchQuery: '',
      missingInvoice: {
        invoiceNumber: '',
        supplierCustomerId: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      invoices: 'invoices/invoices',
      loading: 'invoices/invoicesLoading',
      allTableHeadings: 'invoices/invoicesTableHeadings',
      missingInvoiceModal: 'invoices/missingInvoiceModal',
      missingInvoiceModalLoading: 'invoices/missingInvoiceModalLoading',
      queryParams: 'invoices/invoicesQueryParams',
      invoicesActions: 'user/invoicesActions',
      customersSuppliers: 'user/customersSuppliers',
      userRole: 'user/userRole',
    }),
    submitMissingInoviceButtonDisabled() {
      return !this.missingInvoice.invoiceNumber || !this.missingInvoice.supplierCustomerId;
    },
    tableHeadings() {
      if (this.$store.state.user.user.portalID === '0') {
        return this.allTableHeadings;
      }

      const tableHeadingsWithoutProducer = {};
      Object.keys(this.allTableHeadings).forEach((key) => {
        if (key === 'dobavljac') {
          return;
        }

        tableHeadingsWithoutProducer[key] = this.allTableHeadings[key];
      });

      return tableHeadingsWithoutProducer;
    },
  },
  activated() {
    this.$store.dispatch('invoices/refreshInvoicesTablePagination');
    this.$store.dispatch('invoices/setFirstColumnTitle', this.userRole);
  },
  methods: {
    tableHeadingClick(key) {
      this.$store.dispatch('invoices/sortInvoicesTable', key);
    },
    invoicesAction(actionName) {
      this.$store.dispatch('invoices/doInvoicesAction', { actionName });
    },
    inputChange(val) {
      this.searchQuery = val;
    },
    search() {
      this.$store.dispatch('invoices/searchInvoices', this.searchQuery);
    },
    pageChanged() {
      this.$store.dispatch('invoices/getInvoices');
    },
    invoiceClick({ id, dobavljac, broj, datum, receiverId, senderId }) {
      this.$store.dispatch('tablePagination/setCurrentPage', 1);
      this.$store.dispatch('invoices/setInvoiceInfo', {
        id,
        dobavljac,
        broj,
        datum,
        customerId: this.userRole === '1' ? receiverId : senderId,
      });
      this.$store.dispatch('invoices/getInvoice', id);
      this.$emit('switch-component', 'Invoice');
    },
    closeMissingInvoiceModal() {
      this.$store.dispatch('invoices/setMissingInvoiceModal', false);
    },
    selectCustomerSupplier({ id }) {
      this.missingInvoice.supplierCustomerId = id;
    },
    missingInvoiceInputChange(val) {
      this.missingInvoice.invoiceNumber = val;
    },
    submitMissingInvoice() {
      this.$store.dispatch('invoices/submitMissingInvoice', this.missingInvoice);
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices {
  padding: 10px;
  display: flex;
  flex-direction: column;
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__buttons-wrapper {
      display: flex;
      .v-button {
        &.margin-left {
          margin-left: 10px;
        }
      }
    }
  }
  &__table {
    margin-top: 12px;
  }
  &__missing-invoice {
    &__input-item {
      margin-top: 10px;
      &--invoice-number {
        margin-top: 30px;
        margin-bottom: 20px;
      }
      &__input {
        margin-top: 6px;
      }
    }
    &__actions {
      display: flex;
      .v-button {
        &:last-of-type {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
