import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  packingLists: {
    data: [],
    queryParams: {
      pageNum: 1,
      pageSize: 25,
      sortBy: 'datum',
      sortDesc: true,
      query: null,
      customerId: null,
    },
    loading: false,
    tableHeadings: {
      dobavljac: {
        title: 'Dobavljač',
        sortBy: false,
        sortDesc: true,
      },
      broj: {
        title: 'Broj',
        sortBy: false,
        sortDesc: true,
      },
      datum: {
        title: 'Datum',
        sortBy: true,
        sortDesc: true,
      },
    },
  },
  packingList: {
    info: {},
    data: [],
    pageData: [],
    queryParams: {
      pageNum: 1,
      pageSize: 30,
    },
    loading: false,
    tableHeadings: {
      sifra: {
        title: 'Lokalna šifra',
      },
      naziv: {
        title: 'Naziv',
      },
      proizvodjac: {
        title: 'Proizvođač',
      },
      napomena: {
        title: 'Napomena',
      },
      poruceno: {
        title: 'Poručeno',
      },
      prihvaceno: {
        title: 'Prihvaćeno',
      },
    },
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
