<template>
  <div class="orders app__page">
    <div class="orders__actions app__page__actions">
      <div class="orders__actions__buttons-wrapper">
        <v-button
          v-for="(action, index) in ordersActions"
          :class="{ 'margin-left': index !== 0 }"
          :key="action.id"
          @clicked="ordersAction(action.name)"
          :icon-file-name="`${action.name}.png`"
          width="auto"
        >
          {{ action.buttonText }}
        </v-button>
      </div>
      <v-input
        input-placeholder="Pretraži"
        icon-file-name="search.png"
        @value-change="inputChange"
        @icon-clicked="search"
        @keyup.native.enter="search"
      />
    </div>
    <v-table
      class="orders__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @heading-clicked="tableHeadingClick"
      @page-changed="pageChanged"
      show-pagination
      sortable
    >
      <orders-table-row
        v-for="order in orders"
        :order-data="order"
        :key="order.id"
        @click.native="orderClick(order)"
      />
    </v-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';
import VTable from '@/components/table/VTable.vue';
import OrdersTableRow from '@/components/orders/OrdersTableRow.vue';

export default {
  name: 'Orders',
  components: {
    VButton,
    VInput,
    VTable,
    OrdersTableRow,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      orders: 'orders/orders',
      loading: 'orders/ordersLoading',
      allTableHeadings: 'orders/ordersTableHeadings',
      queryParams: 'orders/ordersQueryParams',
      ordersActions: 'user/ordersActions',
      userRole: 'user/userRole',
    }),
    tableHeadings() {
      if (this.$store.state.user.user.portalID === '0') {
        return this.allTableHeadings;
      }

      const tableHeadingsWithoutProducer = {};
      Object.keys(this.allTableHeadings).forEach((key) => {
        if (key === 'dobavljac') {
          return;
        }

        tableHeadingsWithoutProducer[key] = this.allTableHeadings[key];
      });

      return tableHeadingsWithoutProducer;
    },
  },
  activated() {
    this.$store.dispatch('orders/refreshOrdersTablePagination');
  },
  methods: {
    tableHeadingClick(key) {
      this.$store.dispatch('orders/sortOrdersTable', key);
    },
    ordersAction(actionName) {
      if (actionName === 'create_order') {
        this.$router.push('/documents/create-order');
      } else {
        this.$store.dispatch('orders/doOrdersAction', { actionName });
      }
    },
    inputChange(val) {
      this.searchQuery = val;
    },
    search() {
      this.$store.dispatch('orders/searchOrders', this.searchQuery);
    },
    pageChanged() {
      this.$store.dispatch('orders/getOrders');
    },
    orderClick({ id, dobavljac, broj, datum, senderId, receiverId }) {
      this.$store.dispatch('tablePagination/setCurrentPage', 1);
      this.$store.dispatch('orders/setOrderInfo', {
        id,
        dobavljac,
        broj,
        datum,
        customerId: this.userRole === '1' ? senderId : receiverId,
      });
      this.$store.dispatch('orders/getOrder', id);
      this.$emit('switch-component', 'Order');
    },
  },
};
</script>

<style lang="scss" scoped>
.orders {
  padding: 10px;
  display: flex;
  flex-direction: column;
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__buttons-wrapper {
      display: flex;
      .v-button {
        &.margin-left {
          margin-left: 10px;
        }
      }
    }
  }
  &__table {
    margin-top: 12px;
  }
}
</style>
