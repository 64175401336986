<template>
  <div class="new-order" :class="{ 'new-order--active': active }">
    <div class="new-order__toggle-button" @click="toggleActive">
      <span>Narudžbina</span>
      <span>{{ `(${newOrder.length})` }}</span>
      <span class="new-order__toggle-button__arrow">{{ active ? '&#8744;' : '&#8743;' }}</span>
    </div>
    <div class="new-order__table-wrapper" v-if="active">
      <div class="new-order__table-wrapper__placeholder" v-if="!newOrder.length">
        Narudžbina je trenutno prazna.
      </div>
      <v-table
        v-else
        :loading="false"
        :table-headings="newOrderTableHeadings"
        @heading-clicked="tableHeadingClick"
        sortable
      >
        <new-order-table-row
          v-for="rowData in newOrder"
          :new-order-item-data="rowData"
          :key="rowData.id"
          :supplier-id="supplierId"
        />
      </v-table>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VTable from '@/components/table/VTable.vue';
import NewOrderTableRow from '@/components/createOrder/NewOrderTableRow.vue';

export default {
  name: 'NewOrder',
  components: {
    VTable,
    NewOrderTableRow,
  },
  data() {
    return {
      active: false,
    };
  },
  props: {
    supplierId: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters({
      newOrder: 'createOrder/newOrder',
      allNewOrderTableHeadings: 'createOrder/newOrderTableHeadings',
    }),
    newOrderTableHeadings() {
      const tableHeadings = {};
      Object.keys(this.allNewOrderTableHeadings).forEach((key) => {
        const { exists } = this.allNewOrderTableHeadings[key];
        if ((typeof exists === 'boolean' && exists) || exists.includes(this.supplierId)) {
          tableHeadings[key] = this.allNewOrderTableHeadings[key];
        }
      });
      return tableHeadings;
    },
  },
  methods: {
    toggleActive() {
      if (this.active) this.$emit('minimized');
      this.active = !this.active;
    },
    tableHeadingClick(key) {
      this.$store.dispatch('createOrder/sortNewOrderTable', key);
    },
  },
};
</script>

<style lang="scss" scoped>
.new-order {
  position: absolute;
  bottom: 0;
  left: 140px;
  display: flex;
  flex-direction: column;
  &--active {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.5);
    padding-top: 60px;
  }
  &__toggle-button {
    background-color: #ff6666;
    height: 40px;
    width: 190px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      font-size: 18px;
      font-weight: 600;
    }
    &__arrow {
      margin-left: 5px;
      font-size: 22px !important;
    }
  }
  &__table-wrapper {
    background-color: #ffffff;
    flex: 1;
    max-height: 100%;
    overflow-y: auto;
    &__placeholder {
      padding: 10px;
    }
  }
}
</style>
