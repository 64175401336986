<template>
  <div>
    <div
      class="v-table__body__row order-table-row"
      :class="{
        'order-table-row--selectable': selectable,
        'order-table-row--selected': selectable && selected,
      }"
    >
      <div class="v-table__body__row__data" v-if="orderRowData.loading">
        <v-loader :size="18" />
      </div>
      <template v-else>
        <div class="v-table__body__row__data" v-if="userIsBuyer">
          {{ orderRowData.sifra || '-' }}
        </div>
        <template v-else>
          <div class="v-table__body__row__data" v-if="!!orderRowData.sifra && selectable">
            <div
              class="checkbox"
              :class="{ 'checkbox--selected': selected }"
              @click="checkboxClick"
              v-if="selectable"
            ></div>
            {{ orderRowData.sifra }}
          </div>
          <div
            class="v-table__body__row__data"
            v-if="
              !orderRowData.sifra && orderRowData.codeOptions && orderRowData.codeOptions.length > 0
            "
          >
            <div
              class="order-table-row__data__code-options-label"
              :class="{
                'order-table-row__data__code-options-label--active': showCodeOptions,
              }"
              @click="showCodeOptionsClick"
            >
              {{ code || 'Izaberi šifru' }}
            </div>
          </div>
          <div class="v-table__body__row__data" v-if="!orderRowData.codeOptions">
            <div
              class="order-table-row__data__add-code-label"
              :class="{'order-table-row__data__add-code-label--warning': notInCodebook}"
              v-show="!showCodeInput"
              @click="showCodeInputClick"
            >
              {{ code || orderRowData.sifra || 'Dodeli šifru' }}
            </div>
            <div class="order-table-row__data__add-code-content" v-show="showCodeInput">
              <v-input
                ref="codeInput"
                @value-change="inputChange"
                @keyup.native.enter="addCode"
                width="100px"
              />
              <v-button @clicked="addCode" width="auto">Dodeli</v-button>
            </div>
          </div>
        </template>
      </template>
      <div class="v-table__body__row__data">{{ orderRowData.naziv }}</div>
      <div class="v-table__body__row__data">{{ orderRowData.proizvodjac || '-' }}</div>
      <div class="v-table__body__row__data">{{ orderRowData.kolicina }}</div>
      <div
        v-if="supplierId == 1645 || supplierId == 1036"
        class="v-table__body__row__data"
      >
        {{ orderRowData.note || '-' }}
      </div>
      <div
        v-if="supplierId == 1645 || supplierId == 1036"
        class="v-table__body__row__data"
      >
        {{ orderRowData.cena || '-' }}
      </div>
      <div
        v-if="supplierId == 1645"
        class="v-table__body__row__data"
      >
        {{ orderRowData.rabat || '-' }}
      </div>
      <div
        v-if="supplierId == 1645"
        class="v-table__body__row__data"
      >
        {{ orderRowData.discount || '-' }}
      </div>
    </div>
    <div v-if="showCodeOptions">
      <order-table-row
        selectable
        v-for="rowData in codeOptions"
        :selected="rowData.selected"
        @selected="selectedHandler"
        :order-row-data="rowData"
        :key="rowData.id"
      />
    </div>
  </div>
</template>

<script>
import VInput from '@/components/core/VInput.vue';
import VButton from '@/components/core/VButton.vue';
import VLoader from '@/components/core//VLoader.vue';

export default {
  name: 'OrderTableRow',
  components: {
    VInput,
    VButton,
    VLoader,
  },
  props: {
    orderRowData: {
      type: Object,
      requred: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
    supplierId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      code: '',
      showCodeInput: false,
      showCodeOptions: false,
      codeOptions: [],
    };
  },
  computed: {
    userIsBuyer() {
      return this.$store.getters['user/userRole'] === '1';
    },
    notInCodebook() {
      return (this.supplierId === 1645 || this.supplierId === 1036)
        && !this.orderRowData.existsInCodebook;
    },
  },
  methods: {
    inputChange(val) {
      this.code = val;
    },
    addCode() {
      this.showCodeInput = false;
      this.setCode(this.code);
    },
    showCodeInputClick() {
      // disabled until BE changes are made
      // this.$refs.codeInput.value = this.code ? this.code : this.orderRowData.sifra;
      // this.showCodeInput = true;
      // this.$refs.codeInput.focus();
    },
    showCodeOptionsClick() {
      this.codeOptions = this.orderRowData.codeOptions.map((item) => ({
        ...item,
        selected: item.sifra === this.code,
      }));
      this.showCodeOptions = !this.showCodeOptions;
    },
    checkboxClick() {
      this.$emit('selected', {
        selectedId: this.orderRowData.id,
        code: this.orderRowData.sifra,
      });
    },
    selectedHandler({ selectedId, code }) {
      this.codeOptions = this.codeOptions.map((item) => ({
        ...item,
        selected: selectedId === item.id,
      }));
      this.setCode(code);
    },
    setCode(code) {
      if (code) {
        this.code = code;
        const { id } = this.orderRowData;
        this.$emit('code-added', { newCode: code, itemId: id });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  &--selected {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    color: #99a1a7;
    &::after {
      content: '\2714';
      font-size: 10px;
      position: absolute;
      top: 0px;
      left: 2px;
      color: #0077cc;
    }
  }
}
.order-table-row {
  &--selectable {
    background-color: #e6e6e6;
    input[type='checkbox'] {
      margin-right: 2px;
    }
  }
  &--selected {
    font-weight: 700;
  }
  &__data__code-options-label {
    color: #e64c3d;
    cursor: pointer;
    background-image: url('../../assets/img/icons/table/izaberi-sifru-down.png');
    background-repeat: no-repeat;
    background-position: right 7px;
    padding-right: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    &--active {
      background-image: url('../../assets/img/icons/table/izaberi-sifru-up.png');
    }
  }
  &__data__add-code-label {
    // removed until adding code is enabled again
    // cursor: pointer;
    // background-image: url('../../assets/img/icons/table/dodeli-sifru.png');
    // background-repeat: no-repeat;
    // background-position: right 3px;
    // padding-right: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
    &--warning {
      color: #e64c3d;
    }
  }
  &__data__add-code-content {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
</style>
