import Vue from 'vue';
import Vuex from 'vuex';

import User from './user';
import App from './app';
import Invoices from './invoices';
import Orders from './orders';
import CreateOrder from './createOrder';
import Responses from './responses';
import PackingLists from './packingLists';
import TablePagination from './tablePagination';
import Nspharm from './nspharm';
import Snackbar from './snackbar';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: User,
    app: App,
    invoices: Invoices,
    orders: Orders,
    createOrder: CreateOrder,
    responses: Responses,
    packingLists: PackingLists,
    tablePagination: TablePagination,
    nspharm: Nspharm,
    snackbar: Snackbar,
  },
  strict: process.env.NODE_ENV !== 'production',
});
