<template>
  <div class="packingLists app__page">
    <keep-alive>
      <component :is="currentComponent" @switch-component="switchComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import PackingLists from '@/components/packingLists/PackingLists.vue';
import PackingList from '@/components/packingLists/PackingList.vue';

export default {
  name: 'PackingListsView',
  components: {
    PackingLists,
    PackingList,
  },
  data() {
    return {
      currentComponent: 'PackingLists',
    };
  },
  created() {
    this.$store.dispatch('tablePagination/reset');
    this.$store.dispatch('app/setPageTitle', 'Otpremnice');
    this.$store.dispatch('packingLists/resetPackingListsQueryParams');
    this.$store.dispatch('packingLists/getPackingLists');
  },
  methods: {
    switchComponent(componentName) {
      this.currentComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
