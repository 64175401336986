const responses = (state) => state.responses.data;
const responsesQueryParams = (state) => state.responses.queryParams;
const responsesLoading = (state) => state.responses.loading;
const responsesTableHeadings = (state) => state.responses.tableHeadings;
const response = (state) => state.response.data;
const responsePageData = (state) => state.response.pageData;
const responseInfo = (state) => state.response.info;
const responseQueryParams = (state) => state.response.queryParams;
const responseLoading = (state) => state.response.loading;
const responseTableHeadings = (state) => state.response.tableHeadings;

export default {
  responses,
  responsesQueryParams,
  responsesLoading,
  responsesTableHeadings,
  response,
  responsePageData,
  responseInfo,
  responseQueryParams,
  responseLoading,
  responseTableHeadings,
};
