<template>
  <div class="response app__page">
    <div class="response__actions">
      <div class="response__actions__top">
        <v-button @clicked="goBack" icon-file-name="back-arrow.png" width="25px" height="35px" />
        <div class="response__actions__top__info">
          <div class="response__actions__top__info__title">{{ responseInfo.dobavljac }}</div>
          <div class="response__actions__top__info__number">{{ responseInfo.broj }}</div>
        </div>
        <div class="response__actions__top__date">
          {{ responseInfo.datum | formatDate }}
        </div>
      </div>
      <div class="response__actions__bottom">
        <v-button @clicked="order()" icon-file-name="missing_invoice.png" width="auto">
          Naruči nedostupno
        </v-button>
      </div>
    </div>
    <v-table
      class="response__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @page-changed="pageChanged"
      show-pagination
    >
      <response-table-row
        v-for="rowData in response"
        :response-row-data="rowData"
        :key="rowData.id"
      />
    </v-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VTable from '@/components/table/VTable.vue';
import ResponseTableRow from '@/components/responses/ResponseTableRow.vue';

export default {
  name: 'Response',
  components: {
    VButton,
    VTable,
    ResponseTableRow,
  },
  data() {
    return {
      searchQuery: '',
      codes: [],
    };
  },
  computed: {
    ...mapGetters({
      response: 'responses/responsePageData',
      responseInfo: 'responses/responseInfo',
      loading: 'responses/responseLoading',
      tableHeadings: 'responses/responseTableHeadings',
    }),
  },
  methods: {
    goBack() {
      this.codes.length = 0;
      this.$emit('switch-component', 'Responses');
    },
    pageChanged() {
      this.$store.dispatch('responses/responseChangePage');
    },
    order() {
      this.$router.push('/documents/create-order');
    },
  },
};
</script>

<style lang="scss" scoped>
.response {
  display: flex;
  flex-direction: column;
  &__actions {
    padding: 10px 10px 0;
    &__top {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      &__info {
        margin-left: 10px;
        &__title {
          font-size: 16px;
          font-weight: 700;
          color: #666666;
        }
        &__number {
          font-size: 12px;
          color: #666666;
        }
      }
      &__date {
        margin-left: auto;
        color: #666666;
      }
    }
    &__bottom {
      margin-top: 10px;
    }
  }
  &__table {
    padding: 0 10px 10px;
    margin-top: 12px;
  }
  &__save {
    padding-left: 10px;
    background-color: #cccccc;
    height: 45px;
    display: flex;
    align-items: center;
    .v-button {
      &:last-of-type {
        margin-left: 10px;
      }
    }
  }
}
</style>
