import axios from 'axios';

export default class Api {
  constructor() {
    this.baseEndpoint = process.env.VUE_APP_API;
  }

  static setHeaders() {
    const token = localStorage.getItem('token');
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
    return headers;
  }

  async get(url, isBlobResponse = false) {
    const headers = Api.setHeaders();
    if (isBlobResponse) {
      const response = await axios.get(`${this.baseEndpoint}${url}`, { headers, responseType: 'blob' });
      return response;
    }

    const response = await axios.get(`${this.baseEndpoint}${url}`, { headers });
    return response;
  }

  async post(url, payload = null) {
    const headers = Api.setHeaders();
    const response = await axios.post(`${this.baseEndpoint}${url}`, payload, { headers });
    return response;
  }

  async put(url, payload = null) {
    const headers = Api.setHeaders();
    const response = await axios.put(`${this.baseEndpoint}${url}`, payload, { headers });
    return response;
  }

  async publicPost(url, payload) {
    const response = await axios.post(`${this.baseEndpoint}${url}`, payload);
    return response;
  }
}
