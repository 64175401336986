const reset = async ({ commit }) => {
  commit('reset');
};

const setCurrentPage = async ({ commit }, payload) => {
  commit('setCurrentPage', payload);
};

const setNextButtonDisabledState = async ({ commit }, payload) => {
  commit('setNextButtonDisabledState', payload);
};

export default {
  reset,
  setCurrentPage,
  setNextButtonDisabledState,
};
