import OrdersApi from '../../api/orders/index';
import UserApi from '../../api/user/index';

const ordersApi = new OrdersApi();
const userApi = new UserApi();

const getOrders = async ({ commit, getters, rootGetters }) => {
  try {
    commit('setOrdersLoading', true);
    const pageNum = rootGetters['tablePagination/currentPage'];
    const customerId = rootGetters['user/customerId'];
    commit('setOrdersQueryParams', { pageNum, customerId });
    const { ordersQueryParams } = getters;
    const { pageSize } = ordersQueryParams;
    const {
      data: { documents, total },
    } = await ordersApi.getOrders(ordersQueryParams);
    commit('tablePagination/setNextButtonDisabledState', total < pageSize, { root: true });
    commit('setOrders', documents);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setOrdersLoading', false);
  }
};

const setOrdersQueryParams = ({ commit }, payload) => {
  commit('setOrdersQueryParams', payload);
};

const resetOrdersQueryParams = ({ commit }) => {
  commit('setOrdersQueryParams', {
    pageNum: 1,
    pageSize: 25,
    sortBy: 'datum',
    sortDesc: true,
    query: null,
    customerId: null,
  });
};

const refreshOrdersTablePagination = ({ getters, commit }) => {
  const {
    orders,
    ordersQueryParams: { pageNum, pageSize },
  } = getters;
  commit('tablePagination/setCurrentPage', pageNum, { root: true });
  commit('tablePagination/setNextButtonDisabledState', orders.length < pageSize, { root: true });
};

const sortOrdersTable = ({ dispatch, getters }, key) => {
  const { ordersTableHeadings } = getters;
  if (ordersTableHeadings[key].sortBy) {
    dispatch('ordersSortDesc', key);
  } else {
    dispatch('ordersSortBy', key);
  }
};

const ordersSortDesc = ({ commit, dispatch, getters }, key) => {
  commit('setOrdersTableHeadings', { type: 'desc', key });
  const { ordersTableHeadings } = getters;
  dispatch('setOrdersQueryParams', {
    sortDesc: ordersTableHeadings[key].sortDesc,
  });
  dispatch('getOrders');
};

const ordersSortBy = ({ commit, dispatch }, key) => {
  commit('setOrdersTableHeadings', { type: 'by', key });
  dispatch('setOrdersQueryParams', {
    sortDesc: true,
    sortBy: key,
  });
  dispatch('getOrders');
};

const searchOrders = ({ dispatch }, searchQuery) => {
  dispatch('tablePagination/reset', null, { root: true });
  const query = searchQuery || null;
  dispatch('setOrdersQueryParams', { query, pageNum: 1 });
  dispatch('getOrders');
};

const doOrdersAction = async (context, { actionName }) => {
  // TO DO UPLOAD ORDER
  console.log(actionName);
};

const getOrder = async ({ commit, dispatch }, id) => {
  try {
    commit('setOrderLoading', true);
    const {
      data: { stavke, sendOrderAllowed, receiverId },
    } = await ordersApi.getOrder({ id });
    if (stavke) {
      const data = stavke.map((item) => ({
        ...item,
        loading: false,
      }));
      commit('setOrderReadOnClient', id);
      commit('setOrderSupplierId', receiverId);
      commit('setSendOrderAllowed', sendOrderAllowed);
      commit('setOrder', data);
      dispatch('orderChangePage');
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setOrderLoading', false);
  }
};

const setOrderInfo = ({ commit }, payload) => {
  commit('setOrderInfo', payload);
};

const orderChangePage = ({ getters, commit, rootGetters }) => {
  const {
    orderQueryParams: { pageSize },
  } = getters;
  const pageNum = rootGetters['tablePagination/currentPage'];
  const { order } = getters;
  const pageData = order.slice((pageNum - 1) * pageSize, pageNum * pageSize);
  commit('setOrderPageData', pageData);
  commit('tablePagination/setNextButtonDisabledState', pageData.length < pageSize, {
    root: true,
  });
};

const addCode = async ({ commit, getters }, payload) => {
  const {
    orderInfo: { customerId },
  } = getters;
  try {
    commit('setOrderItemsLoading', { items: payload, loading: true });
    await Promise.allSettled(
      payload.map((item) => {
        const data = {
          ...item,
          customerId,
        };
        return userApi.addCode(data);
      }),
    );
    // await dispatch('getOrder', id);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setOrderItemsLoading', { items: payload, loading: false });
  }
};

export default {
  getOrders,
  setOrdersQueryParams,
  resetOrdersQueryParams,
  refreshOrdersTablePagination,
  sortOrdersTable,
  ordersSortDesc,
  ordersSortBy,
  searchOrders,
  doOrdersAction,
  getOrder,
  setOrderInfo,
  orderChangePage,
  addCode,
};
