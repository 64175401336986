import ProductsApi from '../../api/products/index';
import OrdersApi from '../../api/orders/index';

const productsApi = new ProductsApi();
const ordersApi = new OrdersApi();

const getProducts = async (
  { commit, getters, dispatch, rootGetters },
  { supplierId, customerId },
) => {
  try {
    commit('setProductsLoading', true);
    const pageNum = rootGetters['tablePagination/currentPage'];
    commit('setProductsQueryParams', { pageNum });
    const { productsQueryParams } = getters;
    const { pageSize } = productsQueryParams;
    const { data } = await productsApi.getProducts({
      ...productsQueryParams,
      supplierId,
      customerId,
    });
    const total = data.products.length;
    commit('tablePagination/setNextButtonDisabledState', total < pageSize, { root: true });
    commit('setProducts', data);
  } catch {
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Došlo je do greške pri učitavanju proizvoda.',
        status: 'error',
      },
      { root: true },
    );
  } finally {
    commit('setProductsLoading', false);
  }
};

const clearProducts = ({ commit }) => {
  commit('clearProducts');
};

const resetProductsQueryParams = ({ commit }) => {
  commit('setProductsQueryParams', {
    pageNum: 1,
    pageSize: 25,
    sortBy: 'naziv',
    sortDesc: false,
    query: null,
  });
};

const sortProductsTable = ({ dispatch, getters }, { key, supplierId, customerId }) => {
  const { productsTableHeadings } = getters;
  if (productsTableHeadings[key].sortBy) {
    dispatch('productsSortDesc', { key, supplierId, customerId });
  } else {
    dispatch('productsSortBy', { key, supplierId, customerId });
  }
};

const productsSortDesc = ({ commit, dispatch, getters }, { key, supplierId, customerId }) => {
  commit('setProductsTableHeadings', { type: 'desc', key });
  const { productsTableHeadings } = getters;
  commit('setProductsQueryParams', {
    sortDesc: productsTableHeadings[key].sortDesc,
  });
  dispatch('getProducts', { supplierId, customerId });
};

const productsSortBy = ({ commit, dispatch }, { key, supplierId, customerId }) => {
  commit('setProductsTableHeadings', { type: 'by', key });
  commit('setProductsQueryParams', {
    sortDesc: true,
    sortBy: key,
  });
  dispatch('getProducts', { supplierId, customerId });
};

const searchProducts = ({ commit, dispatch }, { searchQuery, supplierId, customerId }) => {
  dispatch('tablePagination/reset', null, { root: true });
  const query = searchQuery || null;
  commit('setProductsQueryParams', { query, pageNum: 1 });
  dispatch('getProducts', { supplierId, customerId });
};

const switchProductsRow = ({ commit, getters }, value) => {
  const { products } = getters;
  const selectedIndex = products.findIndex((item) => item.active);
  if (selectedIndex + value > -1 && selectedIndex + value < products.length) {
    commit('changeActiveRow', { value, selectedIndex });
  }
};

const setActiveProductRow = ({ commit }, code) => {
  commit('setActiveRow', code);
};

const editProductRow = ({ commit }, value) => {
  commit('enableEditAmount', value);
};

const addItemToNewOrder = ({ commit, getters, dispatch }, { payload, addToLocalStorage }) => {
  commit('addItemToNewOrder', payload);
  if (addToLocalStorage) {
    const { newOrder } = getters;
    dispatch('saveToLocalStorage', { items: newOrder });
  }
};

const newOrderItemAmountChange = (
  { commit, getters, dispatch },
  { payload, addToLocalStorage },
) => {
  commit('setAmountOnNewOrderItem', payload);
  if (addToLocalStorage) {
    const { newOrder } = getters;
    dispatch('saveToLocalStorage', { items: newOrder });
  }
};

const removeItemFromNewOrder = ({ commit, getters, dispatch }, { code, addToLocalStorage }) => {
  commit('removeItemFromNewOrder', code);
  if (addToLocalStorage) {
    const { newOrder } = getters;
    dispatch('saveToLocalStorage', { items: newOrder });
  }
};

const sortNewOrderTable = ({ getters, dispatch }, key) => {
  const { newOrderTableHeadings } = getters;
  if (newOrderTableHeadings[key].sortBy) {
    dispatch('newOrderSortDesc', key);
  } else {
    dispatch('newOrderSortBy', key);
  }
};

const newOrderSortDesc = ({ commit }, key) => {
  commit('setNewOrderTableHeadings', { type: 'desc', key });
};

const newOrderSortBy = ({ commit }, key) => {
  commit('setNewOrderTableHeadings', { type: 'by', key });
};

const clearNewOrder = ({ commit }) => {
  commit('clearNewOrder');
};

const saveNewOrder = async (
  { commit, dispatch },
  { items, receiverCustomerId, senderCustomerId, isTenderOrder },
) => {
  commit('setSaveOrderLoading', true);
  const orderItems = items.map((item) => ({
    kolicina: item.amount,
    code: item.code,
    name: item.name,
    producer: item.producer,
  }));
  const payload = {
    receiverCustomerId,
    senderCustomerId,
    isTenderOrder,
    items: orderItems,
  };
  try {
    const response = await ordersApi.createNewOrder(payload);
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Narudžbina je uspešno sačuvana.',
        status: 'success',
      },
      { root: true },
    );
    return response;
  } catch {
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Došlo je do greške pri čuvanju narudžbine.',
        status: 'error',
      },
      { root: true },
    );
  } finally {
    commit('setSaveOrderLoading', false);
  }
  return null;
};

const getSavedOrder = async ({ commit, dispatch }, id) => {
  try {
    const {
      data: { stavke, senderId, receiverId, sendOrderAllowed },
    } = await ordersApi.getOrder({ id });
    stavke.forEach((item) => {
      commit('addItemToNewOrder', {
        producer: item.proizvodjac,
        name: item.naziv,
        code: item.sifra,
        amount: item.kolicina,
        id: item.id,
        price: item.cena,
        rabat: item.rabat,
        discount: item.discount,
        note: item.note,
      });
    });
    return {
      senderId,
      receiverId,
      sendOrderAllowed,
    };
  } catch {
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Došlo je do greške pri učitavanju sačuvane narudžbine.',
        status: 'error',
      },
      { root: true },
    );
  }
  return null;
};

const saveOrderChanges = async ({ commit, dispatch }, { items, orderId, isTenderOrder }) => {
  commit('setSaveOrderLoading', true);
  const orderItems = items.map((item) => ({
    kolicina: item.amount,
    code: item.code,
    name: item.name,
    producer: item.producer,
  }));
  const payload = {
    items: orderItems,
    isTenderOrder,
  };
  try {
    const response = await ordersApi.changeOrder({ id: orderId, payload });
    commit('setSaveOrderLoading', false);
    return response;
  } catch {
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Došlo je do greške pri čuvanju izmena narudžbine.',
        status: 'error',
      },
      { root: true },
    );
  } finally {
    commit('setSaveOrderLoading', false);
  }
  return null;
};

const saveToLocalStorage = ({ rootGetters }, payload) => {
  const userId = rootGetters['user/userId'];
  let localOrder = JSON.parse(localStorage.getItem(userId)) || {};
  localOrder = { ...localOrder, ...payload };
  localStorage.setItem(userId, JSON.stringify(localOrder));
};

const removeFromLocalStorage = ({ rootGetters }) => {
  const userId = rootGetters['user/userId'];
  localStorage.removeItem(userId);
};

const getLocalStorageOrder = async ({ commit, rootGetters }) => {
  const userId = rootGetters['user/userId'];
  const { items, senderId, receiverId } = JSON.parse(localStorage.getItem(userId));
  items.forEach((item) => {
    commit('addItemToNewOrder', item);
  });
  return {
    senderId,
    receiverId,
  };
};

const sendOrder = async ({ commit, dispatch }, id) => {
  commit('setSendOrderLoading', true);
  try {
    const response = await ordersApi.sendOrder(id);
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Narudžbina je uspešno poslata.',
        status: 'success',
      },
      { root: true },
    );
    return response;
  } catch {
    dispatch(
      'snackbar/set',
      {
        visible: true,
        text: 'Došlo je do greške pri slanju narudžbine.',
        status: 'error',
      },
      { root: true },
    );
  } finally {
    commit('setSendOrderLoading', false);
  }
  return null;
};

export default {
  getProducts,
  clearProducts,
  resetProductsQueryParams,
  sortProductsTable,
  productsSortDesc,
  productsSortBy,
  searchProducts,
  switchProductsRow,
  setActiveProductRow,
  editProductRow,
  addItemToNewOrder,
  sortNewOrderTable,
  newOrderSortDesc,
  newOrderSortBy,
  newOrderItemAmountChange,
  removeItemFromNewOrder,
  clearNewOrder,
  saveNewOrder,
  getSavedOrder,
  saveOrderChanges,
  saveToLocalStorage,
  removeFromLocalStorage,
  getLocalStorageOrder,
  sendOrder,
};
