<template>
  <div
    class="v-table-pagination"
    @keyup.arrow-right="changePage(1)"
    @keyup.arrow-left="changePage(-1)"
  >
    <button
      type="button"
      class="v-table-pagination__button v-table-pagination__button--prev"
      @click="changePage(-1)"
      :disabled="prevButtonDisabled"
    ></button>
    <input type="text" class="v-table-pagination__input" :value="currentPage" disabled />
    <button
      type="button"
      class="v-table-pagination__button v-table-pagination__button--next"
      @click="changePage(1)"
      :disabled="nextButtonDisabled"
    ></button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TablePagination',
  computed: {
    ...mapGetters({
      currentPage: 'tablePagination/currentPage',
      nextButtonDisabled: 'tablePagination/nextButtonDisabled',
    }),
    prevButtonDisabled() {
      return this.currentPage === 1;
    },
  },
  methods: {
    changePage(val) {
      const page = this.currentPage + val;
      this.$store.dispatch('tablePagination/setCurrentPage', page);
      this.$emit('page-changed');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-table-pagination {
  display: flex;
  align-items: center;
  &__input {
    width: 50px;
    height: 22px;
    text-align: center;
    border: 1pt #cccccc solid;
    font-size: 14px;
    color: #999999;
  }
  &__button {
    width: 22px;
    height: 22px;
    border: none;
    background-size: cover;
    background-repeat: no-repeat;
    cursor: pointer;
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
    &--prev {
      background-image: url('../../assets/img/icons/button/tbar-prev.png');
      margin-right: 10px;
    }
    &--next {
      background-image: url('../../assets/img/icons/button/tbar-next.png');
      margin-left: 10px;
    }
  }
}
</style>
