<template>
  <div
    class="v-table__body__row responses-table-row"
    :class="{ warning: !responseData.quantitiesMatch, not_warning: responseData.quantitiesMatch }"
  >
    <div class="responses-table-row__data">
    </div>
    <div class="v-table__body__row__data responses-table-row__data"
      v-if="this.$store.state.user.user.portalID === '0'"
    >
      <div class="responses-table-row__data__content">
        {{ responseData.dobavljac }}
      </div>
    </div>
    <div class="v-table__body__row__data responses-table-row__data">
      <div class="responses-table-row__data__content">
        {{ responseData.broj }}
      </div>
    </div>
    <div class="v-table__body__row__data responses-table-row__data">
      {{ responseData.datum | formatDate }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponsesTableRow',
  props: {
    responseData: {
      type: Object,
      requred: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.responses-table-row {
  font-weight: 700;
  &:hover {
    background-color: #e6e6e6;
  }
  &.warning {
    .responses-table-row__data {
      &:first-of-type {
        width: 20px;
        background-image: url('../../assets/img/icons/table/warning.png');
        background-repeat: no-repeat;
        background-position: 5px 8px;
      }
    }
  }
  &.not_warning {
    .responses-table-row__data {
      &:first-of-type {
        width: 20px;
      }
    }
  }
}
</style>
