const setResponses = (state, payload) => {
  state.responses.data = payload;
};

const setResponsesQueryParams = (state, payload) => {
  state.responses.queryParams = {
    ...state.responses.queryParams,
    ...payload,
  };
};

const setResponsesLoading = (state, payload) => {
  state.responses.loading = payload;
};

const setResponsesTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    state.responses.tableHeadings[key].sortDesc = !state.responses.tableHeadings[key].sortDesc;
  }
  if (type === 'by') {
    Object.keys(state.responses.tableHeadings).forEach((item) => {
      state.responses.tableHeadings[item].sortDesc = true;
      state.responses.tableHeadings[item].sortBy = item === key;
    });
  }
};

const setResponse = (state, payload) => {
  state.response.data = payload;
};

const setResponsePageData = (state, payload) => {
  state.response.pageData = payload;
};

const setResponseInfo = (state, payload) => {
  state.response.info = payload;
};

const setResponseQueryParams = (state, payload) => {
  state.response.queryParams = payload;
};

const setResponseLoading = (state, payload) => {
  state.response.loading = payload;
};

export default {
  setResponses,
  setResponsesQueryParams,
  setResponsesLoading,
  setResponsesTableHeadings,
  setResponse,
  setResponsePageData,
  setResponseInfo,
  setResponseQueryParams,
  setResponseLoading,
};
