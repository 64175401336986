<template>
  <div id="app">
    <snackbar />
    <top-bar
      :logo="`${publicPath}${branding.logo}`"
      :background-color-primary="branding.color"
      :background-color-secondary="branding.color2"
      :text-color="branding.topBarTextColor"
      >
    </top-bar>
    <div id="app__loading" v-if="appLoading">
      <v-loader :size="100" :color="branding.color" />
    </div>
    <div id="app__wrapper" v-else>
      <side-nav v-if="showSideNav" :background-color="branding.color3" />
      <main id="app__wrapper__main">
        <router-view id="app__wrapper__main__router-view" />
      </main>
    </div>
  </div>
</template>

<script>
import TopBar from '@/components/layout/TheTopBar.vue';
import SideNav from '@/components/layout/TheSideNavigation.vue';
import Snackbar from '@/components/layout/TheSnackbar.vue';
import VLoader from '@/components/core/VLoader.vue';
import branding from '../public/branding_current.json';

export default {
  name: 'App',
  branding,
  components: {
    TopBar,
    SideNav,
    VLoader,
    Snackbar,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
  computed: {
    appLoading() {
      return this.$store.getters['app/loading'];
    },
    showSideNav() {
      return !this.$route.meta.public;
    },
    token() {
      return this.$store.getters['user/token'];
    },
    branding() {
      return this.$store.getters['app/branding'];
    },
  },
  beforeMount() {
    this.$store.dispatch('app/setBranding', branding);
  },
  mounted() {
    document.title = branding.title;
  },
  created() {
    if (this.token) {
      this.$store.dispatch('user/setUser');
    }
  },
};
</script>

<style lang="scss">
@import './style/global.scss';
#app {
  width: 100%;
  height: 100vh;
  // min-width: 960px;
  min-height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  &__wrapper {
    flex: 1;
    display: flex;
    overflow: hidden;
    &__main {
      flex: 1;
      overflow: auto;
      &__router-view {
        height: 100%;
      }
    }
  }
}
@media only screen and (max-width: 1600px) {
  #app {
    &__wrapper {
      padding-left: 32px;
    }
  }
}
</style>
