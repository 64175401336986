const products = (state) => state.products.data;
const productsQueryParams = (state) => state.products.queryParams;
const productsLoading = (state) => state.products.loading;
const productsTableHeadings = (state) => state.products.tableHeadings;
const newOrder = (state) => state.newOrder.data;
const newOrderTableHeadings = (state) => state.newOrder.tableHeadings;
const isOrderInLocalStorage = () => (userId) => {
  const orderFromLocalStorage = JSON.parse(localStorage.getItem(userId)) || {};
  return orderFromLocalStorage.items !== undefined && !!orderFromLocalStorage.items.length;
};
const loadingSaveOrder = (state) => state.newOrder.loadingSaveOrder;
const loadingSendOrder = (state) => state.newOrder.loadingSendOrder;

export default {
  products,
  productsQueryParams,
  productsLoading,
  productsTableHeadings,
  newOrder,
  newOrderTableHeadings,
  isOrderInLocalStorage,
  loadingSaveOrder,
  loadingSendOrder,
};
