import Api from '../Api';

export default class Responses extends Api {
  async getResponses({ pageNum, pageSize, sortBy, sortDesc, query, customerId }) {
    let url = `/documents/responses?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;
    if (query) {
      url = `${url}&query=${query}`;
    }
    if (customerId) {
      url = `${url}&customerId=${customerId}`;
    }
    const response = await this.get(url);
    return response;
  }

  async getResponse({ id }) {
    const url = `/documents/responses/${id}`;
    const response = await this.get(url);
    return response;
  }
}
