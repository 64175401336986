<template>
  <div class="invoices app__page">
    <keep-alive>
      <component :is="currentComponent" @switch-component="switchComponent"></component>
    </keep-alive>
  </div>
</template>

<script>
import Invoices from '@/components/invoices/Invoices.vue';
import Invoice from '@/components/invoices/Invoice.vue';

export default {
  name: 'InvoicesView',
  components: {
    Invoices,
    Invoice,
  },
  data() {
    return {
      currentComponent: 'Invoices',
    };
  },
  created() {
    this.$store.dispatch('tablePagination/reset');
    this.$store.dispatch('app/setPageTitle', 'Fakture');
    this.$store.dispatch('invoices/resetInvoicesQueryParams');
    this.$store.dispatch('invoices/getInvoices');
  },
  methods: {
    switchComponent(componentName) {
      this.currentComponent = componentName;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
