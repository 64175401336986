var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"portal-info",class:{
    'portal-info--notification': _vm.infoData.type === 'notification',
    'portal-info--lockdown': _vm.infoData.type === 'lockdown',
  }},[_c('div',{staticClass:"portal-info__title",class:{
      'portal-info__title--notification': _vm.infoData.type === 'notification',
      'portal-info__title--lockdown': _vm.infoData.type === 'lockdown',
    }},[_c('strong',[_vm._v(_vm._s(_vm.infoData.type === 'notification' ? 'Obaveštenje' : 'Portal neaktivan'))])]),_c('div',[_c('strong',[_vm._v("Portal:")]),_vm._v(" "+_vm._s(_vm.infoData.name))]),_c('div',[_c('strong',[_vm._v("Period neaktivnost:")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.infoData.lockdownFrom))+" - "+_vm._s(_vm._f("formatDate")(_vm.infoData.lockdownTo))+" ")]),_c('div',[_vm._v(_vm._s(_vm.infoData.message))])])
}
var staticRenderFns = []

export { render, staticRenderFns }