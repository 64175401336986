<template>
  <div class="v-modal__backdrop">
    <div class="v-modal" :style="{ width: `${width}px` }">
      <div class="v-modal__header" :style="{ 'background-color': branding.color }">
        <div class="v-modal__header__title">{{ title }}</div>
        <div class="v-modal__header__close-button" @click="closeModal"></div>
      </div>
      <div class="v-modal__loading" v-if="loading">
        <v-loader :size="60" :color="branding.color" />
      </div>
      <template v-else>
        <div class="v-modal__content">
          <slot name="content"></slot>
        </div>
        <div class="v-modal__actions" v-if="!!$slots.actions">
          <slot name="actions"></slot>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import VLoader from '@/components/core/VLoader.vue';

export default {
  name: 'VModal',
  components: {
    VLoader,
  },
  props: {
    width: {
      type: [Number, String],
      required: false,
      default: 310,
    },
    title: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    branding() {
      return this.$store.getters['app/branding'];
    },
  },
  methods: {
    closeModal() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-modal {
  &__backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(51, 51, 51, 0.5);
    z-index: 999;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 140px;
  }
  &__header {
    height: 35px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 4px 10px;
    &__title {
      color: #ffffff;
      font-size: 16px;
      font-weight: 700;
    }
    &__close-button {
      align-self: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background-image: url('~@/assets/img/icons/modal/close_x.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
  &__loading {
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
  }
  &__content {
    background-color: #ffffff;
    padding: 10px;
  }
  &__actions {
    height: 45px;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-color: #cccccc;
  }
}
</style>
