const invoices = (state) => state.invoices.data;
const invoicesQueryParams = (state) => state.invoices.queryParams;
const invoicesLoading = (state) => state.invoices.loading;
const invoicesTableHeadings = (state) => state.invoices.tableHeadings;
const missingInvoiceModal = (state) => state.invoices.missingInvoiceModal.show;
const missingInvoiceModalLoading = (state) => state.invoices.missingInvoiceModal.loading;
const invoice = (state) => state.invoice.data;
const filteredInvoice = (state) => state.invoice.filteredData;
const invoicePageData = (state) => state.invoice.pageData;
const invoiceInfo = (state) => state.invoice.info;
const invoiceQueryParams = (state) => state.invoice.queryParams;
const invoiceLoading = (state) => state.invoice.loading;
const invoiceTableHeadings = (state) => state.invoice.tableHeadings;

export default {
  invoices,
  invoicesQueryParams,
  invoicesLoading,
  invoicesTableHeadings,
  missingInvoiceModal,
  missingInvoiceModalLoading,
  invoice,
  filteredInvoice,
  invoicePageData,
  invoiceInfo,
  invoiceQueryParams,
  invoiceLoading,
  invoiceTableHeadings,
};
