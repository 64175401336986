const packingLists = (state) => state.packingLists.data;
const packingListsQueryParams = (state) => state.packingLists.queryParams;
const packingListsLoading = (state) => state.packingLists.loading;
const packingListsTableHeadings = (state) => state.packingLists.tableHeadings;
const packingList = (state) => state.packingList.data;
const packingListPageData = (state) => state.packingList.pageData;
const packingListInfo = (state) => state.packingList.info;
const packingListQueryParams = (state) => state.packingList.queryParams;
const packingListLoading = (state) => state.packingList.loading;
const packingListTableHeadings = (state) => state.packingList.tableHeadings;

export default {
  packingLists,
  packingListsQueryParams,
  packingListsLoading,
  packingListsTableHeadings,
  packingList,
  packingListPageData,
  packingListInfo,
  packingListQueryParams,
  packingListLoading,
  packingListTableHeadings,
};
