const setCurrentPage = (state, payload) => {
  state.currentPage = payload;
};

const setNextButtonDisabledState = (state, payload) => {
  state.nextButtonDisabled = payload;
};

const reset = (state) => {
  state.currentPage = 1;
};

export default {
  setCurrentPage,
  setNextButtonDisabledState,
  reset,
};
