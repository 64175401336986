<template>
  <div
    class="v-table__body__row invoices-table-row"
    :class="[
      {
        warning: !invoiceData.paired,
        read: invoiceData.userRead,
        processed: invoiceData.userProcessed,
      },
    ]"
  >
    <div v-if="this.$store.state.user.user.portalID === '0'"
      class="v-table__body__row__data invoices-table-row__data--first-column"
    >
      <span class="invoices-table-row__data--first-column__content">
        {{ invoiceData.dobavljac }}
      </span>
    </div>
    <div class="v-table__body__row__data">{{ invoiceData.broj }}</div>
    <div class="v-table__body__row__data">{{ invoiceData.datum | formatDate }}</div>
  </div>
</template>

<script>
export default {
  name: 'InvoicesTableRow',
  props: {
    invoiceData: {
      type: Object,
      requred: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.invoices-table-row {
  font-weight: 700;
  &:hover {
    background-color: #e6e6e6;
  }
  &.processed {
    font-weight: 400;
  }
  &.read {
    background-color: #e6e6e6;
  }
  &.warning {
    .invoices-table-row__data--first-column {
      background-image: url('../../assets/img/icons/table/warning.png');
      background-repeat: no-repeat;
      background-position: 5px 8px;
      &__content {
        margin-left: 15px;
      }
    }
  }
}
</style>
