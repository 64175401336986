<template>
  <div class="offers app__page">
    <div class="offers__iframe-wrapper">
      <iframe id="imagepgframe" name="imagepgframe" frameborder="0" scrolling="auto" src="https://nspharm.com/a/"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OffersView',
  created() {
    this.$store.dispatch('app/setPageTitle', 'Akcije');
  },
};
</script>

<style lang="scss" scoped>
.offers {
  &__iframe-wrapper {
    height: calc(100% - 5px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
