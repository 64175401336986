const orders = (state) => state.orders.data;
const ordersQueryParams = (state) => state.orders.queryParams;
const ordersLoading = (state) => state.orders.loading;
const ordersTableHeadings = (state) => state.orders.tableHeadings;
const order = (state) => state.order.data;
const orderPageData = (state) => state.order.pageData;
const orderInfo = (state) => state.order.info;
const orderSendingAllowed = (state) => state.order.sendOrderAllowed;
const orderSupplierId = (state) => state.order.orderSupplierId;
const orderQueryParams = (state) => state.order.queryParams;
const orderLoading = (state) => state.order.loading;
const orderTableHeadings = (state) => state.order.tableHeadings;

export default {
  orders,
  ordersQueryParams,
  ordersLoading,
  ordersTableHeadings,
  order,
  orderPageData,
  orderInfo,
  orderSendingAllowed,
  orderSupplierId,
  orderQueryParams,
  orderLoading,
  orderTableHeadings,
};
