<template>
  <div>packing list</div>
</template>

<script>
export default {};
</script>

<style>
</style>
