<template>
  <div class="half-circle-spinner" :style="{ width: `${size}px`, height: `${size}px` }">
    <div
      class="circle circle-1"
      :class="color"
      :style="{ border: `calc(${size}px / 10) solid transparent`, borderTopColor: color }"
    ></div>
    <div
      class="circle circle-2"
      :style="{ border: `calc(${size}px / 10) solid transparent`, borderTopColor: color }"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'VLoader',
  props: {
    size: {
      type: Number,
      required: false,
      default: 60,
    },
    color: {
      type: String,
      required: false,
      default: '#999999',
    },
  },
};
</script>

<style>
.half-circle-spinner,
.half-circle-spinner * {
  box-sizing: border-box;
}

.half-circle-spinner {
  border-radius: 100%;
  position: relative;
}

.half-circle-spinner .circle {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 100%;
}

.half-circle-spinner .circle.circle-1 {
  animation: half-circle-spinner-animation 1s infinite;
}

.half-circle-spinner .circle.circle-2 {
  animation: half-circle-spinner-animation 1s infinite alternate;
}

@keyframes half-circle-spinner-animation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
