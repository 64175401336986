const setInvoices = (state, payload) => {
  state.invoices.data = payload;
};

const setInvoicesQueryParams = (state, payload) => {
  state.invoices.queryParams = {
    ...state.invoices.queryParams,
    ...payload,
  };
};

const setInvoicesLoading = (state, payload) => {
  state.invoices.loading = payload;
};

const setInvoicesTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    state.invoices.tableHeadings[key].sortDesc = !state.invoices.tableHeadings[key].sortDesc;
  }
  if (type === 'by') {
    Object.keys(state.invoices.tableHeadings).forEach((item) => {
      state.invoices.tableHeadings[item].sortDesc = true;
      state.invoices.tableHeadings[item].sortBy = item === key;
    });
  }
};

const setFirstColumnTitle = (state, title) => {
  state.invoices.tableHeadings.dobavljac.title = title;
};

const setMissingInvoiceModal = (state, payload) => {
  state.invoices.missingInvoiceModal.show = payload;
};

const setMissingInvoiceModalLoading = (state, payload) => {
  state.invoices.missingInvoiceModal.loading = payload;
};

const setInvoiceReadOnClient = (state, id) => {
  const index = state.invoices.data.findIndex((item) => item.id === id);
  state.invoices.data[index].userRead = true;
};

const setInvoice = (state, payload) => {
  state.invoice.data = payload;
};

const setFilteredInvoice = (state, payload) => {
  state.invoice.filteredData = payload;
};

const setInvoicePageData = (state, payload) => {
  state.invoice.pageData = payload;
};

const setInvoiceInfo = (state, payload) => {
  state.invoice.info = payload;
};

const setInvoiceQueryParams = (state, payload) => {
  state.invoice.queryParams = payload;
};

const setInvoiceLoading = (state, payload) => {
  state.invoice.loading = payload;
};

const setInvoiceItemsLoading = (state, { items, loading }) => {
  items.forEach((item) => {
    const index = state.invoice.pageData.findIndex((i) => i.id === item.itemId);
    state.invoice.pageData[index].loading = loading;
  });
};

export default {
  setInvoices,
  setInvoicesQueryParams,
  setInvoicesLoading,
  setInvoicesTableHeadings,
  setFirstColumnTitle,
  setMissingInvoiceModal,
  setMissingInvoiceModalLoading,
  setInvoice,
  setFilteredInvoice,
  setInvoicePageData,
  setInvoiceInfo,
  setInvoiceQueryParams,
  setInvoiceLoading,
  setInvoiceReadOnClient,
  setInvoiceItemsLoading,
};
