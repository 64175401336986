const setPackingLists = (state, payload) => {
  state.packingLists.data = payload;
};

const setPackingListsQueryParams = (state, payload) => {
  state.packingLists.queryParams = {
    ...state.packingLists.queryParams,
    ...payload,
  };
};

const setPackingListsLoading = (state, payload) => {
  state.packingLists.loading = payload;
};

const setPackingListsTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    const val = state.packingLists.tableHeadings[key].sortDesc;
    state.packingLists.tableHeadings[key].sortDesc = !val;
  }
  if (type === 'by') {
    Object.keys(state.packingLists.tableHeadings).forEach((item) => {
      state.packingLists.tableHeadings[item].sortDesc = true;
      state.packingLists.tableHeadings[item].sortBy = item === key;
    });
  }
};

const setPackingList = (state, payload) => {
  state.packingList.data = payload;
};

const setPackingListPageData = (state, payload) => {
  state.packingList.pageData = payload;
};

const setPackingListInfo = (state, payload) => {
  state.packingList.info = payload;
};

const setPackingListQueryParams = (state, payload) => {
  state.packingList.queryParams = payload;
};

const setPackingListLoading = (state, payload) => {
  state.packingList.loading = payload;
};

export default {
  setPackingLists,
  setPackingListsQueryParams,
  setPackingListsLoading,
  setPackingListsTableHeadings,
  setPackingList,
  setPackingListPageData,
  setPackingListInfo,
  setPackingListQueryParams,
  setPackingListLoading,
};
