import Vue from 'vue';

export default {
  init() {
    Vue.filter('formatDate', (value) => {
      if (!value) {
        return 'NA';
      }
      return new Date(value).toLocaleDateString();
    });
  },
};
