<template>
  <div class="app__page__table">
    <div class="app__page__table__wrapper">
      <div class="v-table">
        <header class="v-table__header">
          <div
            class="v-table__header__heading"
            v-for="(item, key) in tableHeadings"
            :key="key"
            :class="{
              'v-table__header__heading--active': item.sortBy && !item.disabled,
              'v-table__header__heading--sortable': sortable && !item.disabled,
              'v-table__header__heading--arrow-down': item.sortDesc && sortable && !item.disabled,
            }"
            :style="{ flex: item.columnWidth || '1' }"
            @click="tableHeadingClick(key, item.disabled)"
          >
            {{ item.title }}
          </div>
        </header>
        <div class="v-table__body" v-if="!loading">
          <slot></slot>
        </div>
      </div>
    </div>
    <div class="app__page__table__loader" v-if="loading">
      <v-loader />
    </div>
    <div class="app__page__table__pagination" v-if="showPagination">
      <v-table-pagination @page-changed="pageChanged" />
    </div>
  </div>
</template>

<script>
import VLoader from '@/components/core/VLoader.vue';
import VTablePagination from '@/components/table/VTablePagination.vue';

export default {
  name: 'VTable',
  components: {
    VLoader,
    VTablePagination,
  },
  props: {
    tableHeadings: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: false,
    },
    sortable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    tableHeadingClick(key, disabled) {
      if (disabled) return;
      this.$emit('heading-clicked', key);
    },
    pageChanged() {
      this.$emit('page-changed');
    },
  },
};
</script>

<style lang="scss">
.app__page__table {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &__wrapper {
    flex: 1;
    overflow-y: auto;
    .v-table {
      width: 100%;
      text-align: left;
      &__header {
        display: flex;
        border-bottom: 1px solid #cccccc;
        &__heading {
          // flex: 1;
          background-color: #f9f9f9;
          color: #999999;
          font-size: 14px;
          padding: 6px 8px;
          background-position: right 10px center;
          background-repeat: no-repeat;
          background-size: 10px;
          &--active {
            background-color: #e6e6e6;
          }
          &--sortable {
            background-image: url('~@/assets/img/icons/table/sort-asc.gif');
            cursor: pointer;
            &:hover {
              background-color: #e6e6e6;
            }
          }
          &--arrow-down {
            background-image: url('~@/assets/img/icons/table/sort-desc.gif');
          }
        }
      }
      &__body {
        &__row {
          display: flex;
          border-bottom: 1px solid #cccccc;
          cursor: default;
          &--loader {
            border-bottom: none;
          }
          &__data {
            flex: 1;
            padding: 6px 8px;
            overflow: hidden;
            word-wrap: break-word;
            &--loader {
              padding-top: 20px;
            }
          }
        }
      }
    }
  }
  &__loader {
    flex: 5;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  &__pagination {
    margin-top: 12px;
  }
}
</style>
