<template>
  <div class="packing-lists app__page">
    <div class="packing-lists__actions app__page__actions">
      <v-input
        input-placeholder="Pretraži"
        icon-file-name="search.png"
        @value-change="inputChange"
        @icon-clicked="search"
        @keyup.native.enter="search"
      />
    </div>
    <v-table
      class="packing-lists__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @heading-clicked="tableHeadingClick"
      @page-changed="pageChanged"
      show-pagination
      sortable
    >
      <packing-lists-table-row
        v-for="packingList in packingLists"
        :packingList-data="packingList"
        :key="packingList.id"
        @click.native="packingListClick(packingList)"
      />
    </v-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VInput from '@/components/core/VInput.vue';
import VTable from '@/components/table/VTable.vue';
import PackingListsTableRow from '@/components/packingLists/PackingListsTableRow.vue';

export default {
  name: 'PackingLists',
  components: {
    VInput,
    VTable,
    PackingListsTableRow,
  },
  data() {
    return {
      searchQuery: '',
    };
  },
  computed: {
    ...mapGetters({
      packingLists: 'packingLists/packingLists',
      loading: 'packingLists/packingListsLoading',
      tableHeadings: 'packingLists/packingListsTableHeadings',
      queryParams: 'packingLists/packingListsQueryParams',
    }),
  },
  activated() {
    this.$store.dispatch('packingLists/refreshPackingListsTablePagination');
  },
  methods: {
    tableHeadingClick(key) {
      this.$store.dispatch('packingLists/sortPackingListsTable', key);
    },
    inputChange(val) {
      this.searchQuery = val;
    },
    search() {
      this.$store.dispatch('packingLists/searchPackingLists', this.searchQuery);
    },
    pageChanged() {
      this.$store.dispatch('packingLists/getPackingLists');
    },
    packingListClick({ id, dobavljac, broj, datum }) {
      this.$store.dispatch('tablePagination/setCurrentPage', 1);
      this.$store.dispatch('packingLists/setPackingListInfo', {
        id,
        dobavljac,
        broj,
        datum,
      });
      this.$store.dispatch('packingLists/getPackingList', id);
      this.$emit('switch-component', 'PackingList');
    },
  },
};
</script>

<style lang="scss" scoped>
.packing-lists {
  padding: 10px;
  display: flex;
  flex-direction: column;
  &__actions {
    display: flex;
    align-items: flex-start;
    .v-input {
      margin-left: auto;
    }
  }
  &__table {
    margin-top: 12px;
  }
}
</style>
