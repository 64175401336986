import Vue from 'vue';
import VueRouter from 'vue-router';

import LoginView from '../views/LoginView.vue';
import DashboardView from '../views/DashboardView.vue';
import InvoicesView from '../views/InvoicesView.vue';
import OrdersView from '../views/OrdersView.vue';
import CreateOrderView from '../views/CreateOrderView.vue';
import ResponsesView from '../views/ResponsesView.vue';
import PackingListsView from '../views/PackingListsView.vue';
import SettingsView from '../views/SettingsView.vue';
import OffersView from '../views/OffersView.vue';
import ReclamationsView from '../views/ReclamationsView.vue';
import ContactView from '../views/ContactView.vue';

import auth from './middleware/auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      public: true,
      middleware: auth,
    },
  },
  {
    path: '/',
    name: 'dashboard',
    component: DashboardView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/documents/invoices',
    name: 'invoices',
    component: InvoicesView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/documents/orders',
    name: 'orders',
    component: OrdersView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/documents/create-order',
    name: 'create-order',
    component: CreateOrderView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/documents/responses',
    name: 'responses',
    component: ResponsesView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/documents/packingLists',
    name: 'packing-lists',
    component: PackingListsView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/offers',
    name: 'offers',
    component: OffersView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/reclamations',
    name: 'reclamations',
    component: ReclamationsView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    meta: {
      middleware: auth,
    },
  },
  {
    path: '*',
    redirect: { name: 'invoices-view' },
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

const nextFactory = (context, middleware, index) => {
  const subsequentMiddleware = middleware[index];
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    context.next(...parameters);
    const nextMiddleware = nextFactory(context, middleware, index, 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
};

router.beforeEach((to, from, next) => {
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
