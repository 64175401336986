const setWrongCredentialsError = (state, payload) => {
  state.wrongCredentialsError = payload;
};

const setAuthLoading = (state, payload) => {
  state.authLoading = payload;
};

const setUser = (state, payload) => {
  state.user = payload;
};

const setUserActions = (state, payload) => {
  state.userActions = payload;
};

const setInvoiceActions = (state, payload) => {
  state.invoiceActions = payload;
};

const setInvoicesActions = (state, payload) => {
  state.invoicesActions = payload;
};

const setOrdersActions = (state, payload) => {
  state.ordersActions = payload;
};

const setCreateOrderActions = (state, payload) => {
  state.createOrderActions = payload;
};

const setCustomersLocations = (state, payload) => {
  state.customersLocations = payload;
};

const setCustomersSuppliers = (state, payload) => {
  state.customersSuppliers = payload;
};

const setCustomerId = (state, payload) => {
  state.customerId = payload;
};

const setChangePasswordApiText = (state, payload) => {
  state.changePasswordApiText = payload;
};

const setPortalInfo = (state, payload) => {
  state.portalInfo = payload;
};

export default {
  setWrongCredentialsError,
  setAuthLoading,
  setUser,
  setUserActions,
  setInvoiceActions,
  setInvoicesActions,
  setOrdersActions,
  setCreateOrderActions,
  setCustomersLocations,
  setCustomersSuppliers,
  setCustomerId,
  setChangePasswordApiText,
  setPortalInfo,
};
