<template>
  <div class="invoice app__page">
    <div class="invoice__actions">
      <div class="invoice__actions__top">
        <v-button @clicked="goBack" icon-file-name="back-arrow.png" width="25px" height="35px" />
        <div class="invoice__actions__top__info">
          <div class="invoice__actions__top__info__title">{{ invoiceInfo.dobavljac }}</div>
          <div class="invoice__actions__top__info__number">{{ invoiceInfo.broj }}</div>
        </div>
        <div class="invoice__actions__top__date">
          {{ invoiceInfo.datum | formatDate }}
        </div>
      </div>
      <div class="invoice__actions__bottom">
        <div class="invoice__actions__bottom__buttons-wrapper">
          <v-button
            v-for="(action, index) in invoiceActions"
            :class="{ 'margin-left': index !== 0 }"
            :key="action.id"
            @clicked="invoiceAction(action.name)"
            :icon-file-name="`${action.name}.png`"
            width="auto"
          >
            {{ action.buttonText }}
          </v-button>
        </div>
        <div>
          <v-input
            input-placeholder="Pretraži"
            icon-file-name="search.png"
            @value-change="inputChange"
            @icon-clicked="search"
            @keyup.native.enter="search"
          />
        </div>
      </div>
    </div>
    <v-table
      class="invoice__table"
      :loading="loading"
      :table-headings="tableHeadings"
      @page-changed="pageChanged"
      show-pagination
    >
      <invoice-table-row
        v-for="rowData in invoice"
        :invoice-row-data="rowData"
        :key="rowData.id"
        @code-added="addCode"
      />
    </v-table>
    <div class="invoice__save" v-if="codes.length > 0">
      <v-button @clicked="cancelInvoice" icon-file-name="cancel-icon.png" width="95px">
        Odbaci
      </v-button>
      <v-button @clicked="saveInvoice" icon-file-name="save-icon.png" width="95px">
        Sačuvaj
      </v-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VButton from '@/components/core/VButton.vue';
import VInput from '@/components/core/VInput.vue';
import VTable from '@/components/table/VTable.vue';
import InvoiceTableRow from '@/components/invoices/InvoiceTableRow.vue';

export default {
  name: 'Invoice',
  components: {
    VButton,
    VInput,
    VTable,
    InvoiceTableRow,
  },
  data() {
    return {
      searchQuery: '',
      codes: [],
    };
  },
  computed: {
    ...mapGetters({
      invoice: 'invoices/invoicePageData',
      invoiceInfo: 'invoices/invoiceInfo',
      loading: 'invoices/invoiceLoading',
      tableHeadings: 'invoices/invoiceTableHeadings',
      invoiceActions: 'user/invoiceActions',
    }),
  },
  methods: {
    inputChange(val) {
      this.searchQuery = val;
    },
    search() {
      this.$store.dispatch('invoices/searchInvoice', this.searchQuery);
    },
    invoiceAction(actionName) {
      const { id } = this.invoiceInfo;
      this.$store.dispatch('invoices/doInvoiceAction', { actionName, id });
    },
    goBack() {
      this.codes.length = 0;
      this.$emit('switch-component', 'Invoices');
    },
    pageChanged() {
      this.$store.dispatch('invoices/invoiceChangePage');
    },
    addCode(code) {
      const index = this.codes.findIndex((item) => item.itemId === code.itemId);
      if (index === -1) {
        this.codes.push(code);
      } else {
        this.codes[index].newCode = code.newCode;
      }
    },
    saveInvoice() {
      this.$store.dispatch('invoices/addCode', this.codes);
      this.codes = [];
    },
    cancelInvoice() {
      this.$store.dispatch('invoices/getInvoice', this.invoiceInfo.id);
      this.codes = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  display: flex;
  flex-direction: column;
  &__actions {
    padding: 10px 10px 0;
    &__top {
      display: flex;
      align-items: flex-start;
      border-bottom: 1px solid #ccc;
      padding-bottom: 10px;
      &__info {
        margin-left: 10px;
        &__title {
          font-size: 16px;
          font-weight: 700;
          color: #666666;
        }
        &__number {
          font-size: 12px;
          color: #666666;
        }
      }
      &__date {
        margin-left: auto;
        color: #666666;
      }
    }
    &__bottom {
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      &__buttons-wrapper {
        display: flex;
        .v-button {
          &.margin-left {
            margin-left: 10px;
          }
        }
      }
    }
  }
  &__table {
    padding: 0 10px 10px;
    margin-top: 12px;
  }
  &__save {
    padding-left: 10px;
    background-color: #cccccc;
    height: 45px;
    display: flex;
    align-items: center;
    .v-button {
      &:last-of-type {
        margin-left: 10px;
      }
    }
  }
}
</style>
