<template>
  <div>
    <div
      class="v-table__body__row invoice-table-row"
      :class="{
        'invoice-table-row--selectable': selectable,
        'invoice-table-row--selected': selectable && selected,
      }"
    >
      <div class="v-table__body__row__data" v-if="invoiceRowData.loading">
        <v-loader :size="18" />
      </div>
      <template v-else>
        <div class="v-table__body__row__data" v-if="!!invoiceRowData.sifra && selectable">
          <div
            class="checkbox"
            :class="{ 'checkbox--selected': selected }"
            @click="checkboxClick"
            v-if="selectable"
          ></div>
          {{ invoiceRowData.sifra }}
        </div>
        <div
          class="v-table__body__row__data"
          v-if="!invoiceRowData.sifra && invoiceRowData.codeOptions.length > 0"
        >
          <div
            class="invoice-table-row__data__code-options-label"
            :class="{
              'invoice-table-row__data__code-options-label--active': showCodeOptions,
            }"
            @click="showCodeOptionsClick"
          >
            {{ code || 'Izaberi šifru' }}
          </div>
        </div>
        <div
          class="v-table__body__row__data"
          v-if="invoiceRowData.codeOptions && invoiceRowData.codeOptions.length === 0"
        >
          <div
            class="invoice-table-row__data__add-code-label"
            v-show="!showCodeInput"
            @click="showCodeInputClick"
          >
            {{ code || invoiceRowData.sifra || 'Dodeli šifru' }}
          </div>
          <div class="invoice-table-row__data__add-code-content" v-show="showCodeInput">
            <v-input
              ref="codeInput"
              @value-change="inputChange"
              @keyup.native.enter="addCode"
              width="100px"
            />
            <v-button @clicked="addCode" width="auto">Dodeli</v-button>
          </div>
        </div>
      </template>
      <div class="v-table__body__row__data">{{ invoiceRowData.ean }}</div>
      <div class="v-table__body__row__data">{{ invoiceRowData.naziv }}</div>
      <div class="v-table__body__row__data">{{ invoiceRowData.proizvodjac || '-' }}</div>
      <div class="v-table__body__row__data">{{ invoiceRowData.jedinicaMere }}</div>
      <div class="v-table__body__row__data">{{ invoiceRowData.kolicina }}</div>
    </div>
    <div v-if="showCodeOptions">
      <invoice-table-row
        selectable
        v-for="rowData in codeOptions"
        :selected="rowData.selected"
        @selected="selectedHandler"
        :invoice-row-data="rowData"
        :key="rowData.id"
      />
    </div>
  </div>
</template>

<script>
import VInput from '@/components/core/VInput.vue';
import VButton from '@/components/core/VButton.vue';
import VLoader from '@/components/core//VLoader.vue';

export default {
  name: 'InvoiceTableRow',
  components: {
    VInput,
    VButton,
    VLoader,
  },
  props: {
    invoiceRowData: {
      type: Object,
      requred: true,
    },
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    selected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      code: '',
      showCodeInput: false,
      showCodeOptions: false,
      codeOptions: [],
    };
  },
  methods: {
    inputChange(val) {
      this.code = val;
    },
    addCode() {
      this.showCodeInput = false;
      this.setCode(this.code);
    },
    showCodeInputClick() {
      this.$refs.codeInput.value = this.code ? this.code : this.invoiceRowData.sifra;
      this.showCodeInput = true;
      this.$refs.codeInput.focus();
    },
    showCodeOptionsClick() {
      this.codeOptions = this.invoiceRowData.codeOptions.map((item) => ({
        ...item,
        selected: item.sifra === this.code,
      }));
      this.showCodeOptions = !this.showCodeOptions;
    },
    checkboxClick() {
      this.$emit('selected', {
        selectedId: this.invoiceRowData.id,
        code: this.invoiceRowData.sifra,
      });
    },
    selectedHandler({ selectedId, code }) {
      this.codeOptions = this.codeOptions.map((item) => ({
        ...item,
        selected: selectedId === item.id,
      }));
      this.setCode(code);
    },
    setCode(code) {
      if (code) {
        this.code = code;
        const { id } = this.invoiceRowData;
        this.$emit('code-added', { newCode: code, itemId: id });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 6px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  &--selected {
    background-color: #e9ecee;
    border: 1px solid #adb8c0;
    color: #99a1a7;
    &::after {
      content: '\2714';
      font-size: 10px;
      position: absolute;
      top: 0px;
      left: 2px;
      color: #0077cc;
    }
  }
}
.invoice-table-row {
  &--selectable {
    background-color: #e6e6e6;
    input[type='checkbox'] {
      margin-right: 2px;
    }
  }
  &--selected {
    font-weight: 700;
  }
  &__data__code-options-label {
    color: #e64c3d;
    cursor: pointer;
    background-image: url('../../assets/img/icons/table/izaberi-sifru-down.png');
    background-repeat: no-repeat;
    background-position: right 7px;
    padding-right: 13px;
    overflow: hidden;
    text-overflow: ellipsis;
    &--active {
      background-image: url('../../assets/img/icons/table/izaberi-sifru-up.png');
    }
  }
  &__data__add-code-label {
    color: #e64c3d;
    cursor: pointer;
    background-image: url('../../assets/img/icons/table/dodeli-sifru.png');
    background-repeat: no-repeat;
    background-position: right 3px;
    padding-right: 13px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  &__data__add-code-content {
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
  }
}
</style>
