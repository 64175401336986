import Api from '../Api';

export default class PackingLists extends Api {
  async getPackingLists({ pageNum, pageSize, sortBy, sortDesc, query, customerId }) {
    let url = `/documents/packingLists?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=${sortBy}&sortDesc=${sortDesc}`;
    if (query) {
      url = `${url}&query=${query}`;
    }
    if (customerId) {
      url = `${url}&customerId=${customerId}`;
    }
    const packingList = await this.get(url);
    return packingList;
  }

  async getPackingList({ id }) {
    const url = `/documents/packingLists/${id}`;
    const packingList = await this.get(url);
    return packingList;
  }
}
