import ResponsesApi from '../../api/responses/index';

const responsesApi = new ResponsesApi();

const getResponses = async ({ commit, getters, rootGetters }) => {
  try {
    commit('setResponsesLoading', true);
    const pageNum = rootGetters['tablePagination/currentPage'];
    const customerId = rootGetters['user/customerId'];
    commit('setResponsesQueryParams', { pageNum, customerId });
    const { responsesQueryParams } = getters;
    const { pageSize } = responsesQueryParams;
    const {
      data: { documents, total },
    } = await responsesApi.getResponses(responsesQueryParams);
    commit('tablePagination/setNextButtonDisabledState', total < pageSize, { root: true });
    commit('setResponses', documents);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setResponsesLoading', false);
  }
};

const setResponsesQueryParams = ({ commit }, payload) => {
  commit('setResponsesQueryParams', payload);
};

const resetResponsesQueryParams = ({ commit }) => {
  commit('setResponsesQueryParams', {
    pageNum: 1,
    pageSize: 25,
    sortBy: 'datum',
    sortDesc: true,
    query: null,
    customerId: null,
  });
};

const refreshResponsesTablePagination = ({ getters, commit }) => {
  const {
    responses,
    responsesQueryParams: { pageNum, pageSize },
  } = getters;
  commit('tablePagination/setCurrentPage', pageNum, { root: true });
  commit('tablePagination/setNextButtonDisabledState', responses.length < pageSize, { root: true });
};

const sortResponsesTable = ({ dispatch, getters }, key) => {
  const { responsesTableHeadings } = getters;
  if (responsesTableHeadings[key].sortBy) {
    dispatch('responsesSortDesc', key);
  } else {
    dispatch('responsesSortBy', key);
  }
};

const responsesSortDesc = ({ commit, dispatch, getters }, key) => {
  commit('setResponsesTableHeadings', { type: 'desc', key });
  const { responsesTableHeadings } = getters;
  dispatch('setResponsesQueryParams', {
    sortDesc: responsesTableHeadings[key].sortDesc,
  });
  dispatch('getResponses');
};

const responsesSortBy = ({ commit, dispatch }, key) => {
  commit('setResponsesTableHeadings', { type: 'by', key });
  dispatch('setResponsesQueryParams', {
    sortDesc: true,
    sortBy: key,
  });
  dispatch('getResponses');
};

const searchResponses = ({ dispatch }, searchQuery) => {
  dispatch('tablePagination/reset', null, { root: true });
  const query = searchQuery || null;
  dispatch('setResponsesQueryParams', { query, pageNum: 1 });
  dispatch('getResponses');
};

const getResponse = async ({ commit, dispatch }, id) => {
  try {
    commit('setResponseLoading', true);
    const {
      data: { stavke },
    } = await responsesApi.getResponse({ id });
    if (stavke) {
      commit('setResponse', stavke);
      dispatch('responseChangePage');
    }
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setResponseLoading', false);
  }
};

const setResponseInfo = ({ commit }, payload) => {
  commit('setResponseInfo', payload);
};

const responseChangePage = ({ getters, commit, rootGetters }) => {
  const {
    responseQueryParams: { pageSize },
  } = getters;
  const pageNum = rootGetters['tablePagination/currentPage'];
  const { response } = getters;
  const pageData = response.slice((pageNum - 1) * pageSize, pageNum * pageSize);
  commit('setResponsePageData', pageData);
  commit('tablePagination/setNextButtonDisabledState', pageData.length < pageSize, {
    root: true,
  });
};

export default {
  getResponses,
  setResponsesQueryParams,
  resetResponsesQueryParams,
  refreshResponsesTablePagination,
  sortResponsesTable,
  responsesSortDesc,
  responsesSortBy,
  searchResponses,
  getResponse,
  setResponseInfo,
  responseChangePage,
};
