const setLoading = async ({ commit }, payload) => {
  commit('setLoading', payload);
};

const setPageTitle = async ({ commit }, payload) => {
  commit('setPageTitle', payload);
};

const setBranding = async ({ commit }, payload) => {
  commit('setBranding', payload);
};

export default {
  setLoading,
  setPageTitle,
  setBranding,
};
