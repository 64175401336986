<template>
  <button
    :disabled="disabled"
    class="v-button"
    :class="[
      `v-button--${color}`,
      {
        'v-button--with-text': !!$slots.default,
        'v-button--without-icon': !iconFileName,
        'v-button--disabled': disabled,
      },
    ]"
    :type="buttonType"
    @click="clicked"
    :style="{ width: `${width}`, height: `${height}` }"
  >
    <template v-if="loading">
      <v-loader :size="18" />
    </template>
    <template v-else>
      <img
        class="v-button__icon"
        :src="require(`@/assets/img/icons/button/${iconFileName}`)"
        alt="ikona dugmeta"
        v-if="iconFileName"
      />
      <span class="v-button__text"><slot></slot></span>
    </template>
  </button>
</template>

<script>
import VLoader from './VLoader.vue';

export default {
  name: 'VButton',
  components: {
    VLoader,
  },
  props: {
    buttonType: {
      type: String,
      required: false,
      default: 'button',
    },
    width: {
      type: String,
      required: false,
      default: '84px',
    },
    height: {
      type: String,
      required: false,
      default: '25px',
    },
    color: {
      type: String,
      required: false,
      default: 'gray',
    },
    iconFileName: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    clicked() {
      this.$emit('clicked');
    },
  },
};
</script>

<style lang="scss" scoped>
.v-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background-color: #f1f1f1;
  cursor: pointer;
  border: 1px solid #cccccc;
  padding: 0 4px;
  &--gray {
    color: #999999;
    border-color: #cccccc;
  }
  &--orange {
    color: #ff6666;
    border-color: #ff6666;
  }
  &--with-text {
    .v-button__text {
      margin-left: 4px;
    }
  }
  &--without-icon {
    .v-button__text {
      margin-left: 0px;
    }
  }
  &--disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
</style>
