import jwtDecode from 'jwt-decode';
import UserApi from '../../api/user/index';
import PortalInfoApi from '../../api/portalInfo/index';

const userApi = new UserApi();
const portalInfoApi = new PortalInfoApi();

const login = async ({ commit, dispatch }, payload) => {
  try {
    commit('setAuthLoading', true);
    commit('setWrongCredentialsError', false);
    const {
      data: { token },
    } = await userApi.login(payload);
    localStorage.setItem('token', token);
    await dispatch('setUser');
    return token;
  } catch (error) {
    console.log(error.response);
    if (error.response.data.errorId === 'wrong_credentials') {
      commit('setWrongCredentialsError', true);
    }
    return false;
  } finally {
    commit('setAuthLoading', false);
  }
};

const changePassword = async ({ commit }, payload) => {
  try {
    commit('setAuthLoading', true);
    await userApi.changePassword(payload);
    commit('setChangePasswordApiText', 'Šifra je uspešno promenjena');
    return true;
  } catch (error) {
    if (error.response.data.errorId === 'wrong_password') {
      commit('setChangePasswordApiText', 'Neispravna stara šifra');
      return false;
    }
    if (error.response.data.errorId === 'new_password_equal_to_old') {
      commit('setChangePasswordApiText', 'Nova šifra je ista kao trenutna');
      return false;
    }
    if (error.response.data.errorId) {
      commit(
        'setChangePasswordApiText',
        'Došlo je do greške na serveru. Molimo kontaktirajte podršku.',
      );
      return false;
    }
    return false;
  } finally {
    commit('setAuthLoading', false);
  }
};

const logout = ({ commit, dispatch }) => {
  localStorage.removeItem('token');
  commit('setUser', {});
  commit('setUserActions', []);
  dispatch('app/setPageTitle', '', { root: true });
  dispatch('app/setLoading', false, { root: true });
};

const setUser = async ({ commit, dispatch }) => {
  dispatch('app/setLoading', true, { root: true });
  const token = localStorage.getItem('token');
  const user = jwtDecode(token);
  commit('setUser', user);
  const { data } = await userApi.getUserActions();
  commit('setUserActions', data);
  const { data: invoicesActions } = await userApi.getInvoicesActions();
  commit('setInvoicesActions', invoicesActions);
  const { data: invoiceActions } = await userApi.getInvoiceActions();
  commit('setInvoiceActions', invoiceActions);
  const { data: ordersActions } = await userApi.getOrdersActions();
  commit('setOrdersActions', ordersActions);
  const { data: createOrderActions } = await userApi.getCreateOrderActions();
  commit('setCreateOrderActions', createOrderActions);
  const { data: customersLocations } = await userApi.getCustomersLocations();
  commit('setCustomersLocations', customersLocations);
  if (customersLocations.length === 1) {
    const [location] = customersLocations;
    commit('setCustomerId', location.id);
  }
  const { data: portalInfo } = await portalInfoApi.getPortalInfo();
  commit('setPortalInfo', portalInfo);
  dispatch('app/setLoading', false, { root: true });
};

const setCustomerId = ({ commit }, customerId) => {
  commit('setCustomerId', customerId);
};

const resetChangePasswordApiText = ({ commit }) => {
  commit('setChangePasswordApiText', '');
};

const getCustomersSuppliers = async ({ commit, getters }, id = null) => {
  try {
    const customerId = id || getters.customerId;
    const { data } = await userApi.getCustomersSuppliers({ customerId });
    commit('setCustomersSuppliers', data);
    return data;
  } catch (error) {
    return error;
  }
};

export default {
  login,
  changePassword,
  logout,
  setUser,
  setCustomerId,
  resetChangePasswordApiText,
  getCustomersSuppliers,
};
