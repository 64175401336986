var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"v-table__body__row invoices-table-row",class:[
    {
      warning: !_vm.invoiceData.paired,
      read: _vm.invoiceData.userRead,
      processed: _vm.invoiceData.userProcessed,
    },
  ]},[(this.$store.state.user.user.portalID === '0')?_c('div',{staticClass:"v-table__body__row__data invoices-table-row__data--first-column"},[_c('span',{staticClass:"invoices-table-row__data--first-column__content"},[_vm._v(" "+_vm._s(_vm.invoiceData.dobavljac)+" ")])]):_vm._e(),_c('div',{staticClass:"v-table__body__row__data"},[_vm._v(_vm._s(_vm.invoiceData.broj))]),_c('div',{staticClass:"v-table__body__row__data"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.invoiceData.datum)))])])
}
var staticRenderFns = []

export { render, staticRenderFns }