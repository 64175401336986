const setProducts = (state, payload) => {
  const products = payload.products.map((item) => ({
    ...item,
    active: false,
    editingAmount: false,
  }));

  if (products.length > 0) {
    products[0].active = true;
  }

  state.products.data = products;
};

const clearProducts = (state) => {
  state.products.data = [];
};

const setProductsQueryParams = (state, payload) => {
  state.products.queryParams = {
    ...state.products.queryParams,
    ...payload,
  };
};

const setProductsLoading = (state, payload) => {
  state.products.loading = payload;
};

const setProductsTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    state.products.tableHeadings[key].sortDesc = !state.products.tableHeadings[key].sortDesc;
  }
  if (type === 'by') {
    Object.keys(state.products.tableHeadings).forEach((item) => {
      state.products.tableHeadings[item].sortDesc = true;
      state.products.tableHeadings[item].sortBy = item === key;
    });
  }
};

const changeActiveRow = (state, { value, selectedIndex }) => {
  state.products.data[selectedIndex].active = false;
  state.products.data[selectedIndex + value].active = true;
};

const setActiveRow = (state, code) => {
  state.products.data = state.products.data.map((item) => ({
    ...item,
    active: item.code === code,
  }));
};

const enableEditAmount = (state, value) => {
  const index = state.products.data.findIndex((item) => item.active);
  state.products.data[index].editingAmount = value;
};

const addItemToNewOrder = (state, payload) => {
  const { code } = payload;
  const index = state.newOrder.data.findIndex((item) => item.code === code);
  if (index > -1) {
    state.newOrder.data[index] = payload;
    state.newOrder.data = [...state.newOrder.data];
  } else {
    state.newOrder.data.push(payload);
  }
};

const setAmountOnNewOrderItem = (state, { newAmount, code }) => {
  const index = state.newOrder.data.findIndex((item) => item.code === code);
  state.newOrder.data[index].amount = newAmount;
  state.newOrder.data = [...state.newOrder.data];
};

const removeItemFromNewOrder = (state, code) => {
  state.newOrder.data = state.newOrder.data.filter((item) => item.code !== code);
};

const clearNewOrder = (state) => {
  state.newOrder.data = [];
};

const setNewOrderTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    if (key === 'code' || key === 'amount') {
      if (state.newOrder.tableHeadings[key].sortDesc) {
        state.newOrder.data.sort((a, b) => a[key] - b[key]);
      } else {
        state.newOrder.data.sort((a, b) => b[key] - a[key]);
      }
    } else {
      state.newOrder.data.sort((a, b) => {
        const valA = (a[key] || '').toLowerCase();
        const valB = (b[key] || '').toLowerCase();
        if (valA < valB) {
          return state.newOrder.tableHeadings[key].sortDesc ? -1 : 1;
        }
        if (valA > valB) {
          return state.newOrder.tableHeadings[key].sortDesc ? 1 : -1;
        }
        return 0;
      });
    }
    state.newOrder.tableHeadings[key].sortDesc = !state.newOrder.tableHeadings[key].sortDesc;
  }
  if (type === 'by') {
    Object.keys(state.newOrder.tableHeadings).forEach((item) => {
      state.newOrder.tableHeadings[item].sortDesc = true;
      state.newOrder.tableHeadings[item].sortBy = item === key;
    });
    if (key === 'code' || key === 'amount') {
      state.newOrder.data.sort((a, b) => b[key] - a[key]);
    } else {
      state.newOrder.data.sort((a, b) => {
        const valA = (a[key] || '').toLowerCase();
        const valB = (b[key] || '').toLowerCase();
        if (valA < valB) {
          return 1;
        }
        if (valA > valB) {
          return -1;
        }
        return 0;
      });
    }
  }
};

const setSaveOrderLoading = (state, payload) => {
  state.newOrder.loadingSaveOrder = payload;
};

const setSendOrderLoading = (state, payload) => {
  state.newOrder.loadingSendOrder = payload;
};

export default {
  setProducts,
  clearProducts,
  setProductsQueryParams,
  setProductsLoading,
  setProductsTableHeadings,
  changeActiveRow,
  setActiveRow,
  enableEditAmount,
  addItemToNewOrder,
  setAmountOnNewOrderItem,
  removeItemFromNewOrder,
  clearNewOrder,
  setNewOrderTableHeadings,
  setSaveOrderLoading,
  setSendOrderLoading,
};
