<template>
  <div class="settings app__page">
    <div>Promena lozinke:</div>
    <div class="settings__wrapper">
      <v-input
        v-for="(item, key) in changePasswordFields"
        :key="key"
        :input-type="item.type"
        :input-placeholder="item.placeholder"
        :valid="item.valid"
        :error-message="item.errorMessage"
        width="100%"
        @value-change="inputChange(key, ...arguments)"
        @keyup.native.enter="changePassword"
      />
      <v-button
        @clicked="changePassword"
        icon-file-name="posalji.png"
        :loading="loading"
        width="130px"
      >
        Promeni lozinku
      </v-button>
    </div>
    <div class="settings__api-text" v-if="changePasswordApiText">{{ changePasswordApiText }}</div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VInput from '@/components/core/VInput.vue';
import VButton from '@/components/core/VButton.vue';

export default {
  name: 'SettingsView',
  components: {
    VInput,
    VButton,
  },
  data() {
    return {
      changePasswordFields: {
        oldPassword: {
          type: 'password',
          value: '',
          valid: true,
          errorMessage: 'Polje je obavezno',
          placeholder: 'Stara šifra',
        },
        newPassword: {
          type: 'password',
          value: '',
          valid: true,
          errorMessage: 'Polje je obavezno',
          placeholder: 'Nova šifra',
        },
        newPasswordConfirm: {
          type: 'password',
          value: '',
          valid: true,
          errorMessage: 'Polje je obavezno',
          placeholder: 'Potvrdite novu šifru',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      changePasswordApiText: 'user/changePasswordApiText',
      loading: 'user/authLoading',
    }),
  },
  methods: {
    inputChange(key, value) {
      this.changePasswordFields[key].value = value;
      if (key === 'newPassword') {
        if (value.length === 0) {
          this.changePasswordFields[key].valid = false;
          this.changePasswordFields[key].errorMessage = 'Polje je obavezno';
        } else if (value.length < 8) {
          this.changePasswordFields[key].valid = false;
          this.changePasswordFields[key].errorMessage = 'Šifra mora imati minimum osam karaktera';
        } else if (!/^(?=.*[a-zA-Z])(?=.*\d).+/.test(value)) {
          this.changePasswordFields[key].valid = false;
          this.changePasswordFields[key].errorMessage = 'Šifra mora imati minimum jedno slovo i jedan broj';
        } else {
          this.changePasswordFields[key].valid = true;
          this.changePasswordFields[key].errorMessage = '';
        }
      } else {
        this.changePasswordFields[key].valid = value.length > 0;
        this.changePasswordFields[key].errorMessage = 'Polje je obavezno';
      }
    },
    async changePassword() {
      this.$store.dispatch('user/resetChangePasswordApiText');
      const { changePasswordFields } = this;
      let fieldsValid = true;
      Object.keys(changePasswordFields).forEach((key) => {
        changePasswordFields[key].valid = changePasswordFields[key].value.length > 0;
        fieldsValid = fieldsValid && changePasswordFields[key].valid;
      });
      if (!fieldsValid) {
        return;
      }
      if (
        changePasswordFields.newPassword.value !== changePasswordFields.newPasswordConfirm.value
      ) {
        fieldsValid = false;
        changePasswordFields.newPasswordConfirm.valid = false;
        changePasswordFields.newPasswordConfirm.errorMessage = 'Šifre moraju biti iste';
      }
      if (fieldsValid) {
        this.$store.dispatch('user/changePassword', {
          oldPassword: changePasswordFields.oldPassword.value,
          newPassword: changePasswordFields.newPassword.value,
          newPasswordConfirm: changePasswordFields.newPasswordConfirm.value,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  padding: 10px;
  &__wrapper {
    margin-top: 10px;
    position: relative;
    width: 300px;
    .v-input {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        margin-top: 20px;
      }
    }
    .v-button {
      margin-top: 20px;
    }
    &__credentials-error {
      position: absolute;
      left: 0;
      bottom: -30px;
    }
  }
  &__api-text {
    margin-top: 20px;
  }
}
</style>
