import axios from 'axios';
import router from '@/router';
import store from '@/store';

export default () => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response.status === 401) {
        if (router.currentRoute.meta.public) {
          return Promise.reject(error);
        }
        store.dispatch('user/logout');
        router.push('/login');
      }
      return Promise.reject(error);
    },
  );
};
