import NspharmApi from '../../api/nspharm/index';

const nspharmApi = new NspharmApi();

const getSaldo = async ({ commit }) => {
  try {
    commit('setSaldoLoading', true);
    const {
      data: { saldo },
    } = await nspharmApi.getSaldo();
    commit('setSaldo', saldo);
  } catch (error) {
    console.log(error.response);
  } finally {
    commit('setSaldoLoading', false);
  }
};

export default {
  getSaldo,
};
