<template>
  <div class="v-table__body__row packing-lists-table-row">
    <div class="v-table__body__row__data">{{ packingListData.dobavljac }}</div>
    <div class="v-table__body__row__data">{{ packingListData.broj }}</div>
    <div class="v-table__body__row__data">{{ packingListData.datum | formatDate }}</div>
  </div>
</template>

<script>
export default {
  name: 'PackingListsTableRow',
  props: {
    packingListData: {
      type: Object,
      requred: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.packing-lists-table-row {
  font-weight: 700;
  &:hover {
    background-color: #e6e6e6;
  }
}
</style>
