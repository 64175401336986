<template>
  <aside class="side-navigation" :style="{ backgroundColor }">
    <nav>
      <ul class="side-navigation__nav-item__sub-items">
        <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
          <router-link to="/" class="dashboard">Početna</router-link>
        </li>
        <li
          v-for="action in userActions"
          :key="action.id"
          class="side-navigation__nav-item side-navigation__nav-item--sub-item"
        >
          <template v-if="action.name === 'get_cash_balance'">
            <a @click.prevent="showSaldoModal" href="#" class="saldo">{{ action.buttonText }}</a>
          </template>
          <template v-else>
            <router-link :to="action.apiEndpoint" :class="action.name">
              {{ action.buttonText }}
            </router-link>
          </template>
        </li>
        <template v-if="isNspharm">
          <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
            <router-link to="/contact" class="contact">Kontakti</router-link>
          </li>
          <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
            <router-link to="/offers" class="offers">Akcije</router-link>
          </li>
          <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
            <router-link to="/reclamations" class="reclamations">Reklamacije</router-link>
          </li>
          <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
            <a @click.prevent="downloadInstructions" href="#" class="saldo">Uputstvo</a>
          </li>
        </template>
        <li class="side-navigation__nav-item side-navigation__nav-item--sub-item">
          <router-link to="/settings" class="settings">Podešavanja</router-link>
        </li>
      </ul>
    </nav>
    <v-modal
      class="nspharm-saldo-modal"
      v-if="saldoModal"
      title="Saldo"
      :loading="saldoModalLoading"
      @close-modal="closeSaldoModal"
    >
      <template #content>
        <div class="nspharm-saldo-modal__content">
          <p>Iznos do kredit limita je:</p>
          <p><strong>{{ nspharmSaldo }}</strong></p>
        </div>
      </template>
    </v-modal>
  </aside>
</template>

<script>
import VModal from '@/components/core/VModal.vue';

export default {
  name: 'TheSideNavigation',
  components: {
    VModal,
  },
  props: {
    backgroundColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      saldoModal: false,
    };
  },
  computed: {
    userActions() {
      return this.$store.getters['user/userActions'];
    },
    isNspharm() {
      return this.$store.getters['user/user'].portalID === '1';
    },
    nspharmSaldo() {
      return `${this.$store.getters['nspharm/saldo'].toLocaleString('en-US')} dinara`;
    },
    saldoModalLoading() {
      return this.$store.getters['nspharm/saldoLoading'];
    },
  },
  methods: {
    showSaldoModal() {
      this.saldoModal = true;
      this.$store.dispatch('nspharm/getSaldo');
    },
    closeSaldoModal() {
      this.saldoModal = false;
    },
    downloadInstructions() {
      const link = document.createElement('a');
      link.href = `${process.env.BASE_URL}nspharm-instructions.pdf`;
      link.target = '_blank';
      link.download = 'NsPharmUputstvo';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="scss" scoped>
.side-navigation {
  z-index: 1;
  width: 235px;
  height: 100%;
  background-color: #333333;
  &__nav-item {
    font-size: 16px;
    color: #ffffff;
    height: 40px;
    border: #303030 solid 1px;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    &--sub-item {
      // padding-left: 45px;
      // background-position: 23px center;
      &:hover {
        background-color: #2c2c2c;
      }
      a {
        font-size: 18px;
        color: #ffffff;
        text-decoration: none;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        background-repeat: no-repeat;
        background-position: 8px center;
        background-size: 15px;
        padding-left: 30px;
        background-image: url('../../assets/img/icons/nav/fakture.png');
        &.router-link-exact-active {
          background-color: #2c2c2c;
        }
        &.dashboard {
          background-image: url('../../assets/img/icons/nav/statistika.png');
        }
        &.get_invoices_list {
          background-image: url('../../assets/img/icons/nav/fakture.png');
        }
        &.get_orders_list {
          background-image: url('../../assets/img/icons/nav/narudzbine.png');
        }
        &.get_responses_list {
          background-image: url('../../assets/img/icons/nav/odgovori.png');
        }
        &.settings {
          background-image: url('../../assets/img/icons/nav/podesavanja.png');
        }
        &.offers,
        &.saldo,
        &.contact,
        &.reclamations {
          background-image: none;
        }
      }
    }
    &.documents {
      background-image: url('../../assets/img/icons/nav/dokumenti.png');
    }
    &__sub-items {
      list-style-type: none;
    }
  }
  .nspharm-saldo-modal {
    &__content {
      padding: 10px 10px 20px;
    }
  }
}
@media only screen and (max-width: 1600px) {
  .side-navigation {
    width: 32px;
    position: fixed;
    top: 55px;
    left: 0;
    bottom: 0;
    overflow: hidden;
    transition: width 0.2s ease-out;
    &:hover {
      width: 235px;
    }
  }
}
</style>
