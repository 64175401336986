<template>
  <div class="dashboard app__page">
    <div class="dashboard__content" v-if="!isNspharm">
      <div v-if="customersLocations.length > 1">
        <div>Ukoliko želite možete odabrati korisnika:</div>
        <div class="dashboard__content__select">
          <v-select
            :options="customersLocations"
            :default="defaultSelectValue"
            @input="selectCustomerLocation"
          />
        </div>
      </div>
      <div v-else>
        <div>Korisnik:</div>
        <div class="dashboard__content__location-info">{{ location }}</div>
      </div>
      <div v-if="portalInfo.length" class="dashboard__content__portal-info">
        <div>Informacije:</div>
        <div class="dashboard__content__portal-info__info-wrapper">
          <portal-info v-for="item in portalInfo" :key="item.id" :info-data="item" />
        </div>
      </div>
    </div>
    <div v-else class="dashboard__iframe-wrapper">
      <iframe id="imagepgframe" name="imagepgframe" frameborder="0" scrolling="auto" src="https://nspharm.com/n/"></iframe>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VSelect from '@/components/core/VSelect.vue';
import PortalInfo from '@/components/portalInfo/PortalInfo.vue';

export default {
  name: 'DashboardView',
  components: {
    VSelect,
    PortalInfo,
  },
  computed: {
    ...mapGetters({
      customersLocations: 'user/customersLocations',
      customerId: 'user/customerId',
      portalInfo: 'user/portalInfo',
      user: 'user/user',
    }),
    location() {
      const [location] = this.customersLocations;
      return `${location.name} - ${location.description} - ${location.locationCode}`;
    },
    defaultSelectValue() {
      return this.customersLocations.find((item) => item.id === this.customerId);
    },
    isNspharm() {
      return this.user.portalID === '1';
    },
  },
  created() {
    this.$store.dispatch('app/setPageTitle', 'Početna');
  },
  methods: {
    selectCustomerLocation({ id }) {
      this.$store.dispatch('user/setCustomerId', id);
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  &__content {
    padding: 10px;
    &__select {
      margin-top: 10px;
      max-width: 500px;
    }
    &__location-info {
      margin-top: 10px;
      font-weight: 700;
    }
    &__portal-info {
      margin-top: 40px;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  &__iframe-wrapper {
    height: calc(100% - 5px);
    iframe {
      width: 100%;
      height: 100%;
    }
  }
}
</style>
