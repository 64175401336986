<template>
  <div class="login app__page">
    <div class="login__wrapper">
      <form @submit.prevent autocomplete="on">
        <v-input
          v-for="(item, key) in loginFields"
          :key="key"
          :input-type="item.type"
          :input-placeholder="item.placeholder"
          :valid="item.valid"
          :error-message="item.errorMessage"
          input-autocomplete="on"
          @value-change="inputChange(key, ...arguments)"
          @keyup.native.enter="login"
        />
        <v-button
          @clicked="login"
          button-type="submit"
          icon-file-name="posalji.png"
          width="120px"
          :loading="loading"
        >
          Uloguj se
        </v-button>
      </form>
      <div class="login__wrapper__credentials-error" v-if="wrongCredentials">
        Pogrešan username ili lozinka.
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VInput from '@/components/core/VInput.vue';
import VButton from '@/components/core/VButton.vue';

export default {
  name: 'LoginView',
  components: {
    VInput,
    VButton,
  },
  data() {
    return {
      loginFields: {
        username: {
          type: 'username',
          value: '',
          valid: true,
          errorMessage: 'Polje je obavezno',
          placeholder: 'Korisničko ime',
        },
        password: {
          type: 'password',
          value: '',
          valid: true,
          errorMessage: 'Polje je obavezno',
          placeholder: 'Lozinka',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      wrongCredentials: 'user/wrongCredentialsError',
      loading: 'user/authLoading',
    }),
  },
  methods: {
    inputChange(key, value) {
      this.loginFields[key].value = value;
      this.loginFields[key].valid = value.length > 0;
    },
    async login() {
      const { loginFields } = this;
      let fieldsValid = true;
      Object.keys(loginFields).forEach((key) => {
        loginFields[key].valid = loginFields[key].value.length > 0;
        fieldsValid = fieldsValid && loginFields[key].valid;
      });
      if (fieldsValid) {
        const token = await this.$store.dispatch('user/login', {
          username: loginFields.username.value,
          password: loginFields.password.value,
        });
        if (token) {
          this.$router.push('/');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__wrapper {
    position: relative;
    .v-input {
      &:nth-of-type(2) {
        margin-top: 20px;
      }
    }
    .v-button {
      margin-top: 20px;
      margin-left: auto;
    }
    &__credentials-error {
      position: absolute;
      left: 0;
      bottom: -30px;
    }
  }
}
</style>
