import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  user: {},
  userActions: [],
  invoiceActions: [],
  invoicesActions: [],
  ordersActions: [],
  createOrderActions: [],
  customersLocations: [],
  customersSuppliers: [],
  customerId: null,
  authLoading: false,
  wrongCredentialsError: false,
  changePasswordApiText: '',
  portalInfo: [],
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
