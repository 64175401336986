const setOrders = (state, payload) => {
  state.orders.data = payload;
};

const setOrdersQueryParams = (state, payload) => {
  state.orders.queryParams = {
    ...state.orders.queryParams,
    ...payload,
  };
};

const setOrdersLoading = (state, payload) => {
  state.orders.loading = payload;
};

const setOrdersTableHeadings = (state, { type, key }) => {
  if (type === 'desc') {
    state.orders.tableHeadings[key].sortDesc = !state.orders.tableHeadings[key].sortDesc;
  }
  if (type === 'by') {
    Object.keys(state.orders.tableHeadings).forEach((item) => {
      state.orders.tableHeadings[item].sortDesc = true;
      state.orders.tableHeadings[item].sortBy = item === key;
    });
  }
};

const setOrderReadOnClient = (state, id) => {
  const index = state.orders.data.findIndex((item) => item.id === id);
  state.orders.data[index].userRead = true;
};

const setOrder = (state, payload) => {
  state.order.data = payload;
};

const setOrderPageData = (state, payload) => {
  state.order.pageData = payload;
};

const setOrderInfo = (state, payload) => {
  state.order.info = payload;
};

const setSendOrderAllowed = (state, payload) => {
  state.order.sendOrderAllowed = payload;
};

const setOrderQueryParams = (state, payload) => {
  state.order.queryParams = payload;
};

const setOrderSupplierId = (state, payload) => {
  state.order.orderSupplierId = payload;
};

const setOrderLoading = (state, payload) => {
  state.order.loading = payload;
};

const setOrderItemsLoading = (state, { items, loading }) => {
  items.forEach((item) => {
    const index = state.order.pageData.findIndex((i) => i.id === item.itemId);
    state.order.pageData[index].loading = loading;
  });
};

export default {
  setOrders,
  setOrdersQueryParams,
  setOrdersLoading,
  setOrdersTableHeadings,
  setOrderReadOnClient,
  setOrder,
  setOrderPageData,
  setOrderInfo,
  setSendOrderAllowed,
  setOrderSupplierId,
  setOrderQueryParams,
  setOrderLoading,
  setOrderItemsLoading,
};
