import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  products: {
    data: [],
    queryParams: {
      pageNum: 1,
      pageSize: 25,
      sortBy: 'naziv',
      sortDesc: false,
      query: null,
    },
    loading: false,
    tableHeadings: {
      code: {
        title: 'Šifra',
        sortBy: true,
        sortDesc: true,
        exists: true,
      },
      name: {
        title: 'Naziv',
        sortBy: false,
        sortDesc: true,
        exists: true,
      },
      producer: {
        title: 'Proizvođač',
        sortBy: false,
        sortDesc: true,
        exists: true,
      },
      expirationDate: {
        title: 'Rok trajanja',
        disabled: true,
        exists: [1036, 1645, 9, 913],
      },
      price: {
        title: 'Cena',
        disabled: true,
        exists: [1645, 1036, 9, 913],
      },
      taxRate: {
        title: 'PDV(%)',
        disabled: true,
        exists: [1645, 1036, 9, 913],
        columnWidth: '0.5',
      },
      rabat: {
        title: 'Rabat',
        disabled: true,
        exists: [1645, 913],
        columnWidth: '0.5',
      },
      discount: {
        title: 'Popust',
        disabled: true,
        exists: [1645, 1036, 9, 913],
        columnWidth: '0.5',
      },
      available: {
        title: 'Dostupno',
        disabled: true,
        exists: [1645],
      },
      note: {
        title: 'Napomena',
        disabled: true,
        exists: [1645],
      },
      ean: {
        title: 'Barkod',
        disabled: true,
        exists: [913],
      },
      amount: {
        title: 'Količina',
        disabled: true,
        exists: true,
      },
    },
  },
  newOrder: {
    data: [],
    loadingSaveOrder: false,
    loadingSendOrder: false,
    tableHeadings: {
      code: {
        title: 'Šifra',
        sortBy: false,
        sortDesc: true,
        exists: true,
      },
      name: {
        title: 'Naziv',
        sortBy: true,
        sortDesc: false,
        exists: true,
      },
      producer: {
        title: 'Proizvođač',
        sortBy: false,
        sortDesc: true,
        exists: true,
      },
      expirationDate: {
        title: 'Rok trajanja',
        disabled: true,
        exists: [1036, 1645, 9, 913],
      },
      price: {
        title: 'Cena',
        disabled: true,
        exists: [1645, 1036, 9, 913],
      },
      taxRate: {
        title: 'PDV(%)',
        disabled: true,
        exists: [1645, 1036, 9, 913],
        columnWidth: '0.5',
      },
      rabat: {
        title: 'Rabat',
        disabled: true,
        exists: [1645, 913],
        columnWidth: '0.5',
      },
      discount: {
        title: 'Popust',
        disabled: true,
        exists: [1645, 1036, 9, 913],
        columnWidth: '0.5',
      },
      available: {
        title: 'Dostupno',
        disabled: true,
        exists: [1645, 1036],
      },
      note: {
        title: 'Napomena',
        disabled: true,
        exists: [1645],
      },
      ean: {
        title: 'Barkod',
        disabled: true,
        exists: [913],
      },
      amount: {
        title: 'Količina',
        sortBy: false,
        sortDesc: true,
        exists: true,
      },
    },
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
