<template>
  <div
    class="portal-info"
    :class="{
      'portal-info--notification': infoData.type === 'notification',
      'portal-info--lockdown': infoData.type === 'lockdown',
    }"
  >
    <div
    class="portal-info__title"
      :class="{
        'portal-info__title--notification': infoData.type === 'notification',
        'portal-info__title--lockdown': infoData.type === 'lockdown',
      }"
    >
      <strong>{{ infoData.type === 'notification' ? 'Obaveštenje' : 'Portal neaktivan' }}</strong>
    </div>
    <div><strong>Portal:</strong> {{ infoData.name }}</div>
    <div>
      <strong>Period neaktivnost:</strong>
      {{ infoData.lockdownFrom | formatDate }} - {{ infoData.lockdownTo | formatDate }}
    </div>
    <div>{{ infoData.message }}</div>
  </div>
</template>

<script>
export default {
  name: 'PortalInfo',
  props: {
    infoData: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.portal-info {
  border: 2px solid;
  border-radius: 5px;
  max-width: 500px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &--notification {
    border-color: orange;
  }
  &--lockdown {
    border-color: red;
  }
  &__title {
    text-align: right;
    font-size: 20px;
    &--notification {
      color: orange;
    }
    &--lockdown {
      color: red;
    }
  }
}
</style>
