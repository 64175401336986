<template>
  <Transition>
    <div
      class="snackbar"
      :class="`snackbar--${snackbarStatus}`"
      v-if="snackbarVisible"
    >
        <div class="snackbar__text">{{ snackbarText }}</div>
        <strong class="snackbar__icon-close" @click="hideSnackbar">&#10005;</strong>
    </div>
  </Transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TheSnackbar',
  computed: {
    ...mapGetters({
      snackbarVisible: 'snackbar/visible',
      snackbarText: 'snackbar/text',
      snackbarStatus: 'snackbar/status',
    }),
  },
  methods: {
    hideSnackbar() {
      this.$store.dispatch('snackbar/set', { visible: false });
    },
  },
};
</script>

<style lang="scss" scoped>
.snackbar {
  z-index: 20;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 400px;
  height: 62px;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #fff;
  color: #fff;
  position: fixed;
  top: 20px;
  left: calc(50% - 200px);
  &--info {
    background-color: blue;
  }
  &--success {
    background-color: green;
  }
  &--warning {
    background-color: orange;
  }
  &--error {
    background-color: red;
  }
  &__text {
    font-size: 18px;
  }
  &__icon-close {
    cursor: pointer;
  }
}

.v-enter,
.v-leave-to {
  top: -100px;
}
.v-enter-active,
.v-leave-active {
  transition: top 0.3s ease;
}
</style>
