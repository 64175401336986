<template>
  <div class="v-table__body__row response-table-row" :class="{ warning }">
    <div
      class="v-table__body__row__data response-table-row__data"
      v-for="(item, key) in $store.getters['responses/responseTableHeadings']"
      :key="key"
    >
      <div
        class="response-table-row__data__content"
        :class="{ warning: warning && (key === 'poruceno' || key === 'prihvaceno') }"
      >
        {{ responseRowData[key] || (responseRowData[key] === 0 ? 0 : '-') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResponseTableRow',
  props: {
    responseRowData: {
      type: Object,
      requred: true,
    },
  },
  computed: {
    warning() {
      return this.responseRowData.poruceno > this.responseRowData.prihvaceno;
    },
  },
};
</script>

<style lang="scss" scoped>
.response-table-row {
  &.warning {
    .response-table-row__data {
      &:first-of-type {
        background-image: url('../../assets/img/icons/table/warning.png');
        background-repeat: no-repeat;
        background-position: 5px 8px;
        .response-table-row__data__content {
          margin-left: 15px;
        }
      }
      &__content {
        &.warning {
          color: #e64c3d;
        }
      }
    }
  }
}
</style>
