const setLoading = (state, payload) => {
  state.loading = payload;
};

const setPageTitle = (state, payload) => {
  state.pageTitle = payload;
};

const setBranding = (state, payload) => {
  state.branding = payload;
};

export default {
  setLoading,
  setPageTitle,
  setBranding,
};
